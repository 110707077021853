import { store_med_history } from '@/Api'
import { pushAppierEvent, pushAppierProfile } from '@/GTM'

export default {
  namespaced: true,
  state: () => ({
    history: {
      height: 0,
      weight: 0,
      smoking: "",
      alcoholism: "",
      disease: [],
      symptom: [],
      reason_see_doctor: [],
    }
  }),
  mutations: {
    // RESET(state){
    //   // state.user = {...{}, ...default_state.user}
    //   // state.token = ''
    //   // window.localStorage.removeItem("authtoken");
    // },
  },
  actions: {
    storeHistory(context, {user_id, form}){
      return store_med_history(user_id, form).then(response=> {
        if (response.status == 200 && response?.data?.status === "success") {
          this._vm.$notify({
            title: '成功',
            message: response?.data?.msg,
            type: 'success'
          });
          pushAppierEvent('store_med_history', {
            env: process.env.NODE_ENV,
            user_id: user_id,
          })
          pushAppierProfile({
            user_id: user_id,
            height: form?.height,
            weight: form?.weight,
            smoking_status: form?.smoking,
            drinking_status: form?.alcoholism,
            family_history: form?.disease.join(','),
            symptom: form?.symptom.join(','),
            reason_for_visit: form?.reason_see_doctor.join(',')
          })
          
          return response
        }
        
      })
    },
  },
  getters: {
    
  }
}