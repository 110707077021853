import router from '@/router'
import { 
  login,
  logout,
  user_status,
  signUp,
  user_update,
  save_signature,
  token_login,
  touch_signature,
  getInformConsent
} from '@/Api'
import { pushAppierProfile, pushAppierEvent } from '@/GTM'

const default_state = {
  user: {
    name: '',
    email: '',
    email_verified_at: '',
    personal_id: '',
    info: {
      unit_id: 0,
    },
    signature: null,
    med_history: null
  },
  accessToken: '',
}
export default {
  namespaced: true,
  state: () => ({
    user: {
      name: '',
      email: '',
      email_verified_at: '',
      personal_id: '',
      info: {
        unit_id: 0,
        
      },
      signature: null,
      med_history: null
    },
    informConsent: {id: 0, content: {}},
    accessToken: '',
    inDownloadProgress: false,
  }),
  mutations: {
    RESET(state){
      state.user = {...{}, ...default_state.user}
    },
    RESET_TOKEN(state){
      state.token = ''
      window.localStorage.removeItem("authtoken");
    },
    SET_USER (state, user) {
      // `state` is the local module state
      state.user = {...state.user, ...user}
    },
    SET_USER_INFO (state, info) {
      state.user.info = {...state.user.info, ...info}
    },
    SET_SIGNATURE (state, signature) {
      state.user.signature = {...state.user?.signature, ...signature}
    },
    RESET_SIGNATURE (state) {
      state.user.signature = null
    },
    SET_ACCESS_TOKEN(state, token) {
      if (token) {
        window.localStorage.setItem("authtoken", token);
      } else {
        window.localStorage.removeItem("authtoken");
      }
      state.accessToken = token
    },
    SET_INFORM_CONSENT(state, inform_consent) {
      state.informConsent = {...state.informConsent, ...inform_consent}
    },
  },
  actions: {
    setUser({ commit }, data){
      commit("SET_USER", data);
    },
    doSignIn: async ({commit}, data)=> {
      commit("RESET")
      commit("RESET_TOKEN")
      
      try {
        let response = await login(data);
        if (response.status == 200 && response?.data?.status === "success") {
          pushAppierEvent('login', {
            env: process.env.NODE_ENV,
            user_id: response?.data?.user?.id,
            loginBy: 'email'
          })
          commit("SET_USER", response.data.user);
          commit("SET_ACCESS_TOKEN", response.data.token);
          return response;
        }
      } catch (e) {
        return e.response;
      }
      
      return false;

    },
    doTokenSignIn: async ({dispatch, commit}, data)=> {
      try {
        let response = await token_login(data);
        if (response.status == 200 && response?.data?.status === "success") {
          commit("RESET")
          commit("RESET_TOKEN")
          commit("SET_USER", response.data.user);
          commit("SET_ACCESS_TOKEN", response.data.token);
          pushAppierEvent('login', {
            env: process.env.NODE_ENV,
            user_id: response?.data?.user?.id,
            loginBy: 'social'
          })
          if(response.data.user?.info["data"]?.["unit_id"]){
            router.push({name: 'predict'})
          }
          else {
            router.push({name: 'profile'})
          }
          return response;
        }
      } catch (e) {
        dispatch("logout", true);
        return e.response;
      }
      return false;

    },
    doSignUp: async ({commit}, data)=> {
      commit("RESET")
      commit("RESET_TOKEN")
      try {
        let response = await signUp(data);
        if (response.status == 200 && response?.data?.status === "success") {
          let unit = {id: 0, title: ''}
          if(response.data.user?.units && response.data.user?.units.length){
            unit = response.data.user?.units[0]
          }
          pushAppierEvent('registion_completed', {
            user_id: response.data.user?.id,
            unit_id: unit?.id,
            unit_title: unit?.title
          })
          pushAppierProfile({
            user_id: response.data.user?.id,
            unit_id: unit?.id,
            unit_title: unit?.title
          })
          commit("SET_USER", response.data.user);
          commit("SET_ACCESS_TOKEN", response.data.token);
          return response;
        }
      } catch (e) {
        return e.response;
      }

      return false;

    },
    doUpdateInfo: async ({state, dispatch }, data)=> {
      try {
        let response = await user_update(data);
        if (response.status == 200 && response?.data?.status === "success") {
          pushAppierProfile({
            user_id: state.user.id,
            gender: data?.gender,
            birthday: data?.birthday
          })
          dispatch("getUserStatus")
          return response;
        }
      } catch (e) {
        return e.response;
      }

      return false;
    },
    logout: async ({ state, commit }, need_redirect)=> {
      try {
        let response = await logout();
        if (response.status == 200) {
          if(state?.user?.id){
            pushAppierEvent('logout', {
              env: process.env.NODE_ENV,
              user_id: state?.user?.id,
            })
          }
          
          commit("RESET")
          commit("RESET_TOKEN")
          if(need_redirect)
            router.push({name: 'signIn'})
          return true;
        }
      } catch (e) {
        if(need_redirect)
          router.push({name: 'signIn'})
        return e.response;
      }
    },
    getUserStatus({commit, dispatch}){
      return user_status().then(response=> {
          if (response.status == 200 && response?.data?.status === "success") {
            let temp = response.data.user
            temp.signature = response.data.user?.signatures?.[0]
            commit("RESET")
            commit("SET_USER", temp);
            return response;
          }
        }).catch(e=> {
          let need_redirect = (['signIn', 'home'].indexOf(router.currentRoute.name) != -1) ? false : true;
          dispatch("logout", need_redirect)
          return e
        })
    },
    doUploadSignature({commit}, base64_img){
      return save_signature({base64_img}).then(response=> {
        if (response.status == 200 && response?.data?.status === "success") {
          this._vm.$notify({
            title: '成功',
            message: '儲存簽名成功',
            type: 'success'
          });
          commit("SET_SIGNATURE", response?.data?.data)
          return response
        }
        
      }).catch(e=> {
        this._vm.$notify({
          title: '錯誤',
          message: e.response?.data?.msg,
          type: 'error'
        });
        return e.response
      })
    },
    doUpdateSignatureTime({commit}){
      return touch_signature().then(response=> {
        commit("SET_SIGNATURE", response?.data?.data)
        this._vm.$notify({
          title: '成功',
          message: response?.data?.msg,
          type: 'success'
        });
        
      }).catch(e=> {
        this._vm.$notify({
          title: '錯誤',
          message: e.response?.data?.msg,
          type: 'error'
        });
        return e.response
      })
    },
    resetUserSignature({commit}){
      commit("RESET_SIGNATURE")
    },
    doGetInformConsent({commit}){
      return getInformConsent().then(response=> {
        if (response.status == 200 && response?.data?.status === "success") {
          this._vm.$notify({
            title: '成功',
            message: response?.data?.msg,
            type: 'success'
          });
          commit("SET_INFORM_CONSENT", response?.data?.data)
          return response
        }
      }).catch(e=> {
        this._vm.$notify({
          title: '錯誤',
          message: e.response?.data?.msg,
          type: 'error'
        });
        return e.response
      })
    }
  },
  getters: {
    user: state => state.user,
    accessToken: state => state.accessToken,
    signature: state => state.user?.signature,
    med_history: state => state.user?.med_history,
    one_off_signature: state => state.user?.info?.one_off_signature,
    inform_consent: state => state.informConsent,
  }
}