<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  created() {
    this.logout(true);
  },
  methods: {
    ...mapActions("User", ['logout']),
  }
};
</script>

<style scoped></style>
