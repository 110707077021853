import { 
  user_predictions
} from '@/Api'



export default {
  namespaced: true,
  state: () => ({
    predictions: []
  }),
  mutations: {
    RESET(state){
      state.predictions = []
    },
    SET_PREDICTIONS(state, data){
      state.predictions = [...[], ...data]
    }
  },
  actions: {
    getPredictions: async function(context, {user_id}){
      return user_predictions({user_id}).then(response=> {
        // console.log("user_predictions" ,response)
        if (response.status == 200 && response?.data?.status === "success") {
          context.commit('SET_PREDICTIONS', response?.data?.data)
          return response
        }
        
      })
    },
  },
  getters: {
    predictions: state=> state.predictions
  }
}