<template lang="pug">
div.container
  .row.mb-3.p-5
    .w-100.logo.p-0.m-auto
      img.box-shadow(alt="logo" src="/logo.svg")
  
  .social-login.text-center
    button.google(@click="doSocialLogin('google')")
      font-awesome-icon(size='lg' :icon="['fab', 'google']")
      span.d-inline-block.ml-2 Google
    button.line(@click="doSocialLogin('line')")
      font-awesome-icon(size='lg' :icon="['fab', 'line']")
      span.d-inline-block.ml-2 LINE

  #divide.row.mt-5.px-3
    .col
      .divider
    .col-2.text-center.divide-text
      span OR
    .col
      .divider
  div.form.px-3.my-3
    .form-group
      label(for="email") Email
      input.form-control#email(
        type="text" 
        v-model="username" 
        @blur="$v.username.$touch"
        :class="{ 'is-invalid': $v.username.$error || getErrorStr('email') }"
        aria-describedby="emailHelp" placeholder="test@example.com")
      small.form-text.text-muted#emailHelp test@example.com
      .invalid-feedback(v-if="!$v.username.required") 必填欄位
      .invalid-feedback(v-if="!$v.username.email") 格式錯誤
      .invalid-feedback {{ getErrorStr('email') }}
    .form-group
      label(for="password") 密碼
      input.form-control#password(
        type="password" v-model="password" aria-describedby="personalIdHelp"
        @blur="$v.password.$touch"
        :class="{ 'is-invalid': $v.password.$error || getErrorStr('password') }")
      .invalid-feedback(v-if="!$v.password.required") 必填欄位
      .invalid-feedback(v-if="!$v.password.minLength") 至少8碼
      .invalid-feedback {{ getErrorStr('password') }}
    .form-group.form-check
      input.form-check-input#remember-me(type="checkbox" v-model="remember_me")
      label.form-check-label(for="remember-me") 記住我
    .d-flex.flex-row.align-content-center.justify-content-center
      button.btn.btn-outline-danger.mx-3.w-50.box-shadow(@click.stop.prevent="signIn") 登入
      button.btn.btn-outline-secondary.mx-3.w-50.box-shadow(@click.stop.prevent="signUp") 註冊
  

</template>
<script>
import UserMixin from '@/mixins/UserMixin'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data() {
    return {
      username: '',
      password: '',
      remember_me: false,
      form_errors: {}
    }
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  created(){
    this.runRecaptcha()
    this.getUserStatus().then(response=> {
      if (response.status == 200 && response?.data?.status === "success") {
        this.$router.push({'name': 'predict'})
      }
    })
  },
  methods:{
    runRecaptcha: async function() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      // Do stuff with the received token.
    },
    signIn: async function() {
      this.$v.username.$touch()
      this.$v.password.$touch()

      if(this.$v.username.$invalid || this.$v.password.$invalid) return;
      let response = await this.doSignIn({
        email: this.username,
        password: this.password,
        remember_me: this.remember_me
      })
      
      
      if(response?.data?.status === "success"){
        //route push home page
        this.$notify({
          title: '成功',
          message: '同步資料中',
          type: 'success'
        });
        this.$router.push({name: 'predict'})
      }
      else {
        this.$notify({
          title: '登入失敗',
          message: response?.data?.msg,
          type: 'error'
        });
        //push logout
        if(response?.data?.errors) {
          console.log(response?.data?.errors)
          this.form_errors = response?.data?.errors
        }
      }
    },
    signUp(){
      this.setUser({
        email: this.username,
        password: this.password,
        remember_me: this.remember_me
      })
      this.$router.push({name: 'signUp'})
    },
    doSocialLogin(provider){
      window.location.href = `https://prophet.care/social-sign-up/${provider}`
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  img {
    object-fit: contain;
    width: 100%;
  }
}
.box-shadow{
  -webkit-box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
  -moz-box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
  box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
}
#divide{
  div.divider{
    border: 1px solid #ccc;
    width: 100%;
  }
  .divide-text{
    position: relative;
    top: -10px;
  }
}
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;
.social-login{
  button{
    border: 1px solid #333;
    border-radius: 4px;
    padding: 6px 18px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    width: 40%;
  }
  button.google{
    border-color: #f14235;
    background-color: #b8382e;
    color: #eee;
  }
  button.line{
    border-color: #00b900;
    background-color: #00b900;
    color: #eee;
  }
}
.google-btn {
  margin: 15px auto;
  width: 230px;
  height: 40px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  .google-icon-wrapper {
    position: absolute;
    margin-top: 2px;
    margin-left: 2px;
    width: 36px;
    height: 36px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: 6px;
    margin-left: 6px;
  }
  .btn-text {
    float: right;
    margin: 6px 18px 0 0;
    color: $white;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

</style>