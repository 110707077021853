import axios from 'axios'
import store from './store'
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.interceptors.request.use(function(config){
  store.dispatch('setShowLoading', true)
  return config;
})
axios.interceptors.response.use(function(config){
  setTimeout(()=> {
    store.dispatch('setShowLoading', false)
  }, 1500)
  
  return config;
})

const sanctumCookie = function (config) {
  // Do something before request is sent
  if (config.url.match("/sanctum/csrf-cookie")) {
    return config;
  } else {
    if(process.env.NODE_ENV != 'production')
      axios.get("/sanctum/csrf-cookie");
    else
      axios.get("https://prophet.care/sanctum/csrf-cookie");

  }
  return config;
}

const appendToken = config => {
  let token = window.localStorage.getItem("authtoken");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const api_path = process.env.VUE_APP_API_PATH || "http://localhost:8080/api";
const social_auth_path = process.env.VUE_APP_SOCIAL_AUTH_PATH || "http://localhost:8080/social-sign-up";


const userRequest = axios.create({
  baseURL: `${api_path}/user/`
});
userRequest.interceptors.request.use(sanctumCookie);
userRequest.interceptors.request.use(appendToken);


const apiRequest = axios.create({
  baseURL: `${api_path}`
});
apiRequest.interceptors.request.use(sanctumCookie);
apiRequest.interceptors.request.use(appendToken);


const mhb_downloadRequest = axios.create({
  baseURL: `${api_path}/mhb_download/`
});
mhb_downloadRequest.interceptors.request.use(sanctumCookie);
mhb_downloadRequest.interceptors.request.use(appendToken);

const med_historyRequest = axios.create({
  baseURL: `${api_path}/med_history/`
});
med_historyRequest.interceptors.request.use(sanctumCookie);
med_historyRequest.interceptors.request.use(appendToken);


const socialAuthRequest = axios.create({
  baseURL: `${social_auth_path}`
});
socialAuthRequest.interceptors.request.use(sanctumCookie);
socialAuthRequest.interceptors.request.use(appendToken);

export const login = data => userRequest.post("/login", data);
export const logout = ()=> userRequest.post("/logout", null);
export const token_login = data => userRequest.post("/social_login", data);



export const signUp = data => userRequest.post("/sign-up", data);
export const user_update = data => userRequest.put(`${data.id}`, data);
export const user_status = ()=> userRequest.post("/status", null);
export const user_predictions = () => apiRequest.get(`/prediction`, null);
export const unit_list = ()=> apiRequest.get("/unit", null);
export const cancer_list = ()=> apiRequest.get("/cancer", null);
export const save_signature = data => apiRequest.post("/signature", data);
export const touch_signature = signature => apiRequest.put(`/signature/${signature.id}`, null);

export const get_downloads = () => mhb_downloadRequest.get('');
export const store_mhb_info = data => mhb_downloadRequest.post(`/${data.user_id}`, data);
export const mhb_download = data => mhb_downloadRequest.get(`/${data.user_id}/download`);
export const store_med_history = (user_id, data) => med_historyRequest.post(`/${user_id}`, data);

export const social_login = (provider, response) => socialAuthRequest.post(`/${provider}`, response); 


export const getInformConsent = () => apiRequest.get("/inform_consent");
