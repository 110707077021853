<template lang="pug">
footer.bg-light.py-5
  GDPR
  .container
    .small.text-left.text-muted
      p Copyright &copy; 2022 - PROPHET CARE
      p 早覺醫學有限公司(籌備中)
      p 網頁建置 by Localhost co.
      p
        | 信箱:
        a(:href='`mailto:${$contactEmail}`') {{$contactEmail}}
</template>
<script>
import GDPR from '@/components/GDPR'
export default {
  components: {
    GDPR
  },
}
</script>