<template lang="pug">
.main-menu.position-fixed.text-center.pb-1(v-if="showMenu")
  .d-flex.flex-row.justify-content-center.align-middle
    ul.nav
      li.nav-item
        router-link(class="nav-link" :class="{'active': linkNeedActived == 'home'}" :to="{name: 'home'}")
          div.link-active-bar
          font-awesome-icon(size="2x" :icon="['fas', 'home']")
          div.link-text 首頁
      li.nav-item
        router-link(class="nav-link" :class="{'active': linkNeedActived == 'myhealthbank'}" :to="{name: 'myhealthbank'}")
          div.link-active-bar
          font-awesome-icon(size="2x" :icon="['fas', 'book-medical']")
          div.link-text 健康存摺
    ul.nav
      li.nav-item.home-tab
        router-link(class="nav-link" :class="{'active': linkNeedActived == 'predict'}" :to="{name: 'predict'}")
          font-awesome-icon(size="3x" :icon="['fas', 'eye']")
    ul.nav
      li.nav-item
        router-link(class="nav-link" :class="{'active': linkNeedActived == 'devices'}" :to="{name: 'devices'}")
          div.link-active-bar
          font-awesome-icon(size="2x" :icon="['fas', 'link']")
          div.link-text 穿戴裝置
      li.nav-item
        router-link(class="nav-link" :class="{'active': linkNeedActived == 'setting'}" :to="{name: 'setting'}")
          div.link-active-bar
          font-awesome-icon(size="2x" :icon="['far', 'user-circle']")
          div.link-text 設定
</template>

<script>
export default {
  computed: {
    showMenu() {
      if(this.$inApp?.isMobile){
        return this.$route?.meta?.show_menu
      }
      return this.$route?.meta?.show_menu
    },
    linkNeedActived(){
      if(this.$route?.meta?.highLight){
        return this.$route?.meta?.highLight
      } else {
        let links = ['home', 'myhealthbank', 'predict', 'devices', 'setting']
        return links.indexOf(this.$route.name) != -1 ? this.$route.name : 'predict'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-link{
  width: 19vw;
  padding: 3px 5px;
  color: #555;
  .link-text{
    font-size: 12px !important;
    font-weight: bolder;
    letter-spacing: 2px;
    margin-top: 3px;
  }
  
    
  .link-active-bar{
    position: relative;
    top: -3px;
    border: 1px solid #fff;
    border-radius: 10px;
    width: 80%;
    margin: 0px auto;
  }
  &.active{
    color: #007bff;
    .link-active-bar{
      border-color: #007bff;
    }
      
      
    
  }
  
}

.home-tab a{
  width: 76px !important;
  height: 76px !important;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  top: -16px;
  padding: 10px 5px;
  border: 2px solid #ddd;
  border-radius: 50%;
  background-color: #fff;
  &.active{
    color: #eee;
    background-color: #007bff;
  }
}
</style>



