<template lang="pug">
.container
  .py-3.m-0.px-md-0
    form.form-inline.my-2.my-lg-0
      input.form-control.mr-sm-2(type='search' placeholder='Search' aria-label='Search')
  #histories.accordion
    .card(v-for="(predict, index) in predict_result" :key="`predict_${predict.id}`")
      .card-header(:id="`heading_${predict.id}`")
        h2.mb-0
          button.btn.btn-link.btn-block.text-left(type='button' data-toggle='collapse' :data-target="`#collapse_${predict.id}`" aria-expanded='true' :aria-controls="`collapse_${predict.id}`")
            | {{predict.created_at.toLocaleDateString()}}
      .collapse(:class="{'show': index==0}" :id="`collapse_${predict.id}`" :aria-labelledby="`heading_${predict.id}`" data-parent='#histories')
        .card-body.p-1(v-if="predict.data && predict.data.length > 0")
          .row(v-for="data in predict.data" :key="`predict_${predict.id}_${data.cancer_type}`" v-if="showResultByGender(data.cancer_type)")
            .col-12
              .w-100.cancer.py-3.px-1.d-flex.flex-row.justify-content-left.align-self-center
                .img-div
                  img.align-self-center(:src="cancers[data.cancer_type].img_path")
                .cancer-content.px-2.w-75.d-flex.flex-column.align-self-center
                  h4 {{cancers[data.cancer_type].title}}
                    router-link(class="btn btn-outline-secondary rounded-circle" :to="{name: 'cancer', params: {type: data.cancer_type}}")
                      font-awesome-icon(:icon="['fas', 'info']")
                  .score-bar-parent.position-relative(ref='score_bar_parent')
                    .score(:style="{left:  data.position + 'px'}")
                      div.score-value.text-center {{ data.score_str }}
                      font-awesome-icon(class="font-icon" :icon="['fas', 'caret-down']")
                    .score-bar
        .card-body.p-1(v-else)
          | 請洽

    .card.default_card(v-if="!default_hide")
      .card-header
        h2.mb-0 default
      .card-body.p-1
        .row
          .col-12
            .w-100.cancer.py-3.px-1.d-flex.flex-row.justify-content-left.align-self-center
              .img-div
              .cancer-content.px-2.w-75.d-flex.flex-column.align-self-center
                h4 default
                .score-bar-parent.position-relative(ref='score_bar_default')
                  .score
                  .score-bar


  //- getCaretPosition(cancer.score)+
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// @ is an alias to /src
import UserMixin from '@/mixins/UserMixin'
export default {
  name: 'Histories',
  mixins: [UserMixin],
  computed: {
    ...mapGetters({cancers: 'cancers'}),
  },
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      predict_result: [],
      default_hide: false,
      bar_width: 0,
    }
  },
  created(){
    this.getCancers()
  },
  mounted(){
    this.bar_width = this.$refs.score_bar_default.clientWidth;
    this.default_hide = true;
    this.doGetPredictions()
      
    
    
  },
  methods: {
    ...mapActions({
      getCancers: 'getCancers'
    }),
    doGetPredictions: async function(){
      let response = await this.getPredictions({user_id: this.user.id})
      if(response.status == 200){
        this.predict_result = this.predictions.map(predict=> {
          predict.data = predict.data.map(cancer=> {
            
            cancer.position = this.bar_width - 18
            if(cancer.score < 0.001){
              cancer.score_str = '<0.001'
            } else {
              cancer.score_str = Math.round(cancer.score * 1000) / 1000 + ''
            }
          
            return cancer
          })
          predict.created_at = new Date(predict.created_at)
          return predict
        })
        setTimeout(this.caculatePosition, 1000)
      }
    },
    showResultByGender(cancer_type){
      if(this.gender && this.cancers[cancer_type]){
        if(this.cancers[cancer_type]["gender"] == 'all' || this.gender == 'unknown'){
          return true;
        } else if(this.cancers[cancer_type]["gender"] == this.gender ){
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getCaretPosition(score){
      if(this.$refs.score_bar_parent && this.$refs.score_bar_parent.length > 0){
        let width = this.bar_width;
        // let width = 100;
        return (width * score / 100) - 18 
      } else {
        return this.bar_width - 18
      }
      
    },
    caculatePosition(){
      let temp = [...[], ...this.predictions]
      temp.map(predict=> {
        predict.data = predict.data.map(cancer=> {
          cancer.position = this.getCaretPosition(cancer.score)
          return cancer
        })
        predict.created_at = new Date(predict.created_at)
        return predict
      })

      this.predict_result = [...[], ...temp]
    }
  }
}
</script>
<style lang="scss" scoped>
.default_card{
  opacity: 0;
}
.cancer{
  cursor: pointer;
  transition: 0.5s;
  .img-div{
    border: 1px solid #ccc;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    width: 10vh;
    height: 10vh;
    margin-right: 1rem;
    img{
      object-fit: cover;
      max-width: 100%;
    }
  }
  
  .cancer-content{
    a{
      margin-left: 10px;
      font-size: 10px;
      padding: 1px 8px;
    }
  }
}
.score-bar-parent{
  width: 95%;
  .score{
    transition: left 1.5s;
    transition-delay: 1.5s;
    transition-timing-function: ease-in-out;
    position: absolute;
    top: 0;
    left: calc(100% - 18px);
    width: 60px;
    .font-icon{
      position: absolute;
      top: 13px;
      left: 10px;
      font-size: 24px;
    }
    .score-value{
      position: relative;
      left: -0.5rem;
    }
  }
  .score-bar {
    width: 100%;
    height: 10px;
    margin-top: 30px;
    background: linear-gradient(to right,  rgb(0, 255, 0) 0% , rgb(250, 228, 0) 50%,rgb(255, 0, 0) 100%)
  }
}

</style>