<template lang="pug">
div.container-fluid.text-center
  .row.content.my-1(v-if="signature && signature.id")
    .col-12(v-for="(path, index) in consent_imgs" :key="path")
      img.w-100(:src="path" :alt="`page ${index+1}`")
    .col-12.py-5 完成時間 {{signature.updated_at}}
  .w-100.border-top.py-3.action-btns
    .d-flex.flex-row.justify-content-center.align-self-center
      button.btn.btn-outline-secondary.mx-2.w-50(@click="reSignConsent") 重新簽署
      button.btn.btn-primary.mx-2.w-50(v-if="this.downloadProgress" @click="downloadMhb") 下載健康存摺
      router-link(v-else class="btn btn-outline-secondary mx-2 w-50" :to="{name: 'setting'}") 回設定頁

</template>
<script>
import UserMixin from '@/mixins/UserMixin'
import UserMhbMixin from '@/mixins/UserMhbMixin'
export default {
  mixins: [UserMixin, UserMhbMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      consent_imgs: [
        '/img/inform-consent/page1.jpg',
        '/img/inform-consent/page2.jpg',
        '/img/inform-consent/page3.jpg',
        '/img/inform-consent/page4.jpg',
        '/img/inform-consent/page5.jpg',
        '/img/inform-consent/page6.jpg',
      ]
    }
  },
  created(){
    this.doGetInformConsent()
  },
  mounted(){
    if(this.inform_consent?.id){
      this.replaceInformConsent(this.inform_consent?.content)
    }
  },
  methods: {
    downloadMhb(){
      this.doDownload({user_id: this.user.id})
    },
    reSignConsent(){
      this.$router.push({name: 'signature'})
    },
    replaceInformConsent(newContent){
      let temp = [...[], ...this.consent_imgs]
      Object.keys(newContent).forEach(page_no=> {
        if(page_no > 0){
          temp[page_no - 1] = newContent[page_no]?.['img_path']
        }
      })
      this.consent_imgs = temp
    }
  },
  watch: {
    inform_consent: {
      handler: function(newVal, oldVal){
        this.replaceInformConsent(newVal?.content)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
div.action-btns{
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #efefef;
  
}
div.content{
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>