<template lang="pug">
div
  #main-carousel.carousel.slide(data-ride='carousel')
    ol.carousel-indicators
      li.active(data-target='#main-carousel' data-slide-to='0')
      li(data-target='#main-carousel' data-slide-to='1')
      li(data-target='#main-carousel' data-slide-to='2')
    .carousel-inner
      .carousel-item.active
        picture
          source(srcset='/img/test/pic1.webp' type='image/webp')
          source(srcset='/img/test/pic1.jpg' type='image/png')
          img.w-100.m-auto(src='/img/test/pic1.webp' alt='Alt Text!')

        .carousel-caption.d-none.d-md-block
          //- h5 First slide label
          //- p Nulla vitae elit libero, a pharetra augue mollis interdum.
      .carousel-item
        picture
          source(srcset='/img/home/production-description.webp' type='image/webp')
          source(srcset='/img/home/production-description.jpg' type='image/png')
          img.w-100.m-auto(src='/img/home/production-description.webp' alt='Alt Text!')
        .carousel-caption.d-none.d-md-block
          //- h5 Second slide label
          //- p Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      .carousel-item
        picture
          source(srcset='/img/test/pic3.webp' type='image/webp')
          source(srcset='/img/test/pic3.jpg' type='image/png')
          img.w-100.m-auto(src='/img/test/pic3.webp' alt='Alt Text!')
        .carousel-caption.d-none.d-md-block
          //- h5 Third slide label
          //- p Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    a.carousel-control-prev(href='#main-carousel' role='button' data-slide='prev')
      span.carousel-control-prev-icon(aria-hidden='true')
      span.sr-only Previous
    a.carousel-control-next(href='#main-carousel' role='button' data-slide='next')
      span.carousel-control-next-icon(aria-hidden='true')
      span.sr-only Next

  .container-fluid.my-3
    button.btn.btn-lg.btn-outline-primary.w-100.my-3.startPredict(@click="startPredict") 
      font-awesome-icon(class="mr-3" :icon="['fas', 'plus']")
      | {{predict_btn_str}}預測
    router-link(class="btn btn-outline-secondary w-100 my-3" :to="{'name': 'histories'}") 歷史紀錄
    
  
</template>

<script>
import { pushAppierEvent } from '@/GTM.js'
import { mapGetters } from 'vuex';
// @ is an alias to /src
import UserMixin from '@/mixins/UserMixin'
import UserMhbMixin from '@/mixins/UserMhbMixin'
export default {
  name: 'Home',
  mixins: [UserMixin, UserMhbMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      cancer_imgs: [
        {img: "/img/cancer-icons/prostate.jpg", name: '攝護腺癌', gender: 'male'},
        {img: "/img/cancer-icons/breast.png", name: '乳癌', gender: 'female'},
        {img: "/img/cancer-icons/lungs.png", name: '肺癌', gender: 'all'},
      ],
      gender_list: ['male', 'female', 'unknown']
    }
  },
  computed: {
    ...mapGetters("User", ["user"]),
    predict_btn_str(){
      if(this.gender == 'male') return '男性癌症風險'
      else if(this.gender == 'female') return '女性癌症風險'
      else return '癌症風險'
    },
    gender(){
      return this.user?.info?.gender
    },
    filtered_cancer_imgs (){
      if(this.gender_list.indexOf(this.gender) != -1){
        return this.cancer_imgs.filter(row=> row.gender == 'all' || row.gender == this.gender)
      }
      return this.cancer_imgs
    }
  },
  mounted(){
    this.getMHBHistories()
  },
  methods: {
    
    startPredict(){
      pushAppierEvent('predict_cancer_risk', {
        env: process.env.NODE_ENV,
        user_id: this.user?.id,
      })
      if(this.needToEditProfile(this.user?.info)){
        //go edit profile
        this.$router.push({name: 'profile'})
        return;
      }
      // if(this.one_off_signature){
      //   if(this.user?.signature && this.user?.signature.id > 0){
      //     //check signature is one time or not
      //     //if one time then check the date is in two weeks. 

      //   }
      //   else {
      //     this.$router.push({name: 'profile'})
      //     return
      //   }
      // } else {
      //   //show signed inform consent

      // }

      
      
      
      this.$router.push({'name': 'myhealthbank'})
    }
  }
}
</script>
<style lang="scss" scoped>
.carousel-item{
  img{
    object-fit: cover;
    height: 25vh;
  }
}
.cancer-icon{
  img{
    object-fit: cover;
    width: 28px;
    height: 28px;
  }
}
.startPredict{
  font-size: 2em;
}
.cancer{
  border: 1px solid #ddd;
  cursor: pointer;
  transition: 0.5s;
  
  .font-icon{
    font-size: 5.64em;
    margin: 0 auto;
  }
  &:hover{
    border: 1px solid rgb(41, 88, 243);
  }
  &:active{
    border: 1px solid rgb(41, 88, 243);
  }
}
</style>