import { mapActions, mapGetters } from 'vuex'
// import { pushAppierEvent } from '@/GTM.js'
export default{
  beforeRouteEnter (to, from, next) {
    if(to?.meta?.authorization_required){
      next(vm=> {
        vm.getUserStatus().then(response=> {
          if (response.status == 200 && response?.data?.status === "success") {
            // pushAppierEvent(`page_view_${to?.name}`, {
            //   env: process.env.NODE_ENV,
            //   user_id: response?.data?.data?.user?.id,
            //   page_title: to?.meta?.title,
            //   page_path: to.path
            // })
          }
          
        })
      })
    }
    else {
      // pushAppierEvent(`page_view_${to?.name}`, {
      //   page_title: to?.meta?.title,
      //   page_path: to.path
      // })
      next()
    }
    
  },
  data() {
    return {
      form_errors: null
    }
  },
  computed: {
    ...mapGetters("User", ["user", "signature", 'med_history', 'one_off_signature', 'inform_consent']),
    ...mapGetters("UserPredictions", ["predictions"]),
    gender(){
      return this.user?.info?.gender || 'all'
    }
  },
  methods: {
    ...mapActions("User", [
      "doSignIn",
      'logout',
      'setUser',
      "getUserStatus",
      "doSignUp",
      "doUpdateInfo",
      "doUploadSignature",
      "resetUserSignature",
      "doTokenSignIn",
      'doUpdateSignatureTime',
      'doGetInformConsent'
    ]),
    ...mapActions("UserPredictions", [
      "getPredictions"
    ]),
    getErrorStr(field){
      if(this.form_errors?.[field])
        return this.form_errors?.[field].join(',')
      else 
        return ""
    },
    needToEditProfile(profile){
      if(profile){
        if(profile?.unit_id && profile?.gender && profile.birthday) return false;
        else return true;
      }
      else return true;
    },
  }
}