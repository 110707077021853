<template lang="pug">
.container-fluid
  .content.my-3
    el-steps(:active="2" align-center)
      el-step(title="同意書" icon="el-icon-document")
      el-step(title="下載" icon="el-icon-download")
      el-step(title="預測" icon="el-icon-s-data")
  p.text-center.border-top.my-3.py-3.text-muted 登入健康存摺並下載資料
  form.my-3
    .form-group
      .form-group
        label(for="personal_id") 身份證
        input.form-control#personal_id(
          type="text" 
          :class="{ 'is-invalid': $v.form.personal_id.$error || getErrorStr('personal_id_hash') }"
          @blur="$v.form.personal_id.$touch"
          v-model="form.personal_id" 
          aria-describedby="personalIdHelp")
        .invalid-feedback(v-if="!$v.form.personal_id.required") 必填欄位
        .invalid-feedback(v-if="!$v.form.personal_id.alphaNum") 格式錯誤
        .invalid-feedback(v-if="!$v.form.personal_id.minLength") 格式錯誤
        .invalid-feedback {{ getErrorStr('personal_id_hash') }}
      label.d-block 健保卡卡號
      .form-row
        .col
          input.form-control#card_no1(
            type="tel"
            v-model="form.card_no1"
            placeholder="0000"
            max="9999"
            @blur="$v.form.card_no1.$touch"
            :class="{ 'is-invalid': $v.form.card_no1.$error || getErrorStr('card_no1') }")
          .invalid-feedback(v-if="!$v.form.card_no1.required") 必填
          .invalid-feedback(v-if="!$v.form.card_no1.maxLength") 格式錯誤
          .invalid-feedback {{ getErrorStr('card_no1') }}
        .col
          input.form-control#card_no2(
            type="tel"
            v-model="form.card_no2"
            placeholder="1234"
            max="9999"
            @blur="$v.form.card_no2.$touch"
            :class="{ 'is-invalid': $v.form.card_no2.$error || getErrorStr('card_no2') }")
          .invalid-feedback(v-if="!$v.form.card_no2.required") 必填
          .invalid-feedback(v-if="!$v.form.card_no2.maxLength") 格式錯誤
          .invalid-feedback {{ getErrorStr('card_no2') }}
        .col
          input.form-control#card_no3(
            type="tel"
            v-model="form.card_no3"
            placeholder="5678"
            max="9999"
            @blur="$v.form.card_no3.$touch"
            :class="{ 'is-invalid': $v.form.card_no3.$error || getErrorStr('card_no3') }")
          .invalid-feedback(v-if="!$v.form.card_no3.required") 必填
          .invalid-feedback(v-if="!$v.form.card_no3.maxLength") 格式錯誤
          .invalid-feedback {{ getErrorStr('card_no3') }}
          
    .form-group
      label(for="mhb_password") 健保卡網路服務註冊密碼
      input.form-control#mhb_password(
        type="password" 
        v-model="form.mhb_password" 
        aria-describedby="personalIdHelp"
        @blur="$v.form.card_no3.$touch"
        :class="{ 'is-invalid': $v.form.mhb_password.$error || getErrorStr('mhb_password') }")
      .invalid-feedback(v-if="!$v.form.mhb_password.required") 必填
      .invalid-feedback {{ getErrorStr('mhb_password') }}

    .d-flex.flex-row.align-content-center.justify-content-center.my-5
      button.btn.btn-outline-danger.w-100.box-shadow(@click.stop.prevent="savePersonalInfo") 下載
  
  ul.nav.nav-justified.mt-5
    li.nav-item
      a.nav-link(:href="getMhbLinkByOs" target="_blank") 申請健保卡密碼
    li.nav-item
      router-link(class="nav-link" :to="{name: 'inform-consent'}") 簽署同意書

</template>
<script>
import { pushAppierEvent } from '@/GTM'
import { validationMixin } from 'vuelidate'
import { required, maxLength, alphaNum, minLength } from 'vuelidate/lib/validators'
import UserMixin from '@/mixins/UserMixin'
import UserMhbMixin from '@/mixins/UserMhbMixin'
export default {
  mixins: [UserMixin, UserMhbMixin, validationMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      form: {
        personal_id: null,
        mhb_password: null,
        card_no1: null,
        card_no2: null,
        card_no3: null,
      }
    }
  },
  computed: {
    getDeviceOs(){
      let ua = this.$inApp.ua.toLowerCase();
      if(ua.includes('android')){
        return 'android'
      } else if(ua.includes('iphone')){
        return 'ios'
      } else {
        return 'windows'
      }
    },
    getMhbLinkByOs(){
      if(this.getDeviceOs == 'android'){
        return 'https://play.google.com/store/apps/details?id=com.nhiApp.v1&hl=zh_TW'
      }
      else if(this.getDeviceOs == 'ios'){
        return 'https://apps.apple.com/tw/app/%E5%85%A8%E6%B0%91%E5%81%A5%E4%BF%9D%E8%A1%8C%E5%8B%95%E5%BF%AB%E6%98%93%E9%80%9A-%E5%81%A5%E5%BA%B7%E5%AD%98%E6%91%BA/id578186283'
      }
      else {
        return 'https://myhealthbank.nhi.gov.tw/ihke0002/ihke0002s14.aspx'
      }
    }
  },
  validations: {
    form: {
      personal_id: {
        required,
        alphaNum,
        minLength: minLength(10)
      },
      mhb_password: {
        required,
      },
      card_no1: {
        required,
        maxLength: maxLength(4)
      },
      card_no2: {
        required,
        maxLength: maxLength(4)
      },
      card_no3: {
        required,
        maxLength: maxLength(4)
      },
    }
  },
  mounted(){
    this.initForm()
    
  },
  methods: {
    savePersonalInfo(){
      this.$v.form.$touch()
      if(!this.$v.form.$invalid){
        let data = {
          ...this.form,
          ...{
            user_id: this.user.id,
          }
        }
        this.storeInfo(data).then(response=> {
          if (response.status == 200 && response?.data?.status === "success") {
            this.downloadData()
          }
        })
      }
    },
    downloadData(){
      this.toggleDownloadProgress()
      if(this.signature && this.signature?.id){
        //check signature updated_at 
        //if more then two weeks, ask user resign or use old signature
        let days = 14
        if(this.moreThenDays(new Date(this.signature?.updated_at), days)){
          this.$confirm('受試者同意書簽署時間已超過兩星期，請問是否要重新簽署呢？', '簽署時間已超時', {
              distinguishCancelAndClose: true,
              confirmButtonText: '否，直接下載',
              cancelButtonText: '是，重新簽署'
            })
              .then(() => {
                this.doUpdateSignatureTime().then(()=> {
                  this.doDownload({user_id: this.user.id})
                })
              })
              .catch(action => {
                if(action === 'cancel'){
                  this.$router.push({name: 'inform-consent'})
                }
              });
        } else {
          this.doDownload({user_id: this.user.id})
        }
      } else {
        this.$confirm(`是否同意${this.$appName}使用您的健康存摺資料？`, '尚未簽署同意書', {
          confirmButtonText: '前往簽署',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }).then(() => {
          this.$router.push({name: 'inform-consent'})
        }).catch(() => {
          this.$notify({
            type: 'info',
            title: '已取消'
          });
        });
      }
    },
    moreThenDays(date, days){
      let today = new Date();
      let date2 = new Date(date);
      let diffTime = Math.abs(today - date2);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays >= days
    },
    initForm(){
      if(this.user?.id){
        pushAppierEvent('my_healthly_bank', {
          env: process.env.NODE_ENV,
          user_id: this.user?.id,
        })
      } 
      let temp = {...{}, ...this.user}
      this.form.name = temp.name
      this.form.gender = temp?.info.gender
      this.form.birthday = temp?.info.birthday
      this.form.card_no1 = temp?.info.card_no1
      this.form.card_no2 = temp?.info.card_no2
      this.form.card_no3 = temp?.info.card_no3
    },
  },
  watch: {
    'user.id': function(newVal, oldVal){
      this.initForm()
      return oldVal
    }
  }
}
</script>
<style lang="scss" scoped>

ul.nav{
  li:nth-child(2n+1){
    border-right: 1px solid #eee;
  }
}
</style>