<template lang="pug">
.modal.fade(
    :id="modal_id"
    data-backdrop='static' 
    data-keyboard='false' 
    tabindex='-1' 
    aria-hidden='true')
  .modal-dialog.modal-dialog-centered.modal-dialog-scrollable
    .modal-content
      .modal-header
        h5.modal-title {{title}}
        button.close(type='button' data-dismiss='modal' aria-label='Close' @click="$emit('cancel')")
          span(aria-hidden='true') &times;
      .modal-body
        slot(name="modal_body")
      .modal-footer
        slot(name="modal_footer")

</template>

<script>
export default {
  props: {
    modal_id: {
      default: 'modal_id',
      type: String
    },
    title: {
      default: '',
      type: String
    }
  },
}
</script>