import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import User from './modules/User'
import Mhb from './modules/Mhb'
import MedHistory from './modules/MedHistory'
import UserPredictions from './modules/UserPredictions'
import { unit_list, cancer_list } from '@/Api'
export default new Vuex.Store({
  modules: {
    User,
    Mhb,
    MedHistory,
    UserPredictions
  },
  state: () => ({
    units: [],
    cancers: {},
    show_loading: false,
  }),
  mutations: {
    SET_UNITS (state, list){
      state.units = list
    },
    SET_CANCERS (state, list){
      state.cancers = {...{}, ...list}
    },
    SET_SHOW_LOADING (state, show){
      state.show_loading = show
    }
  },
  actions: {
    getUnits: async ({commit})=> {
      try{
        let response = await unit_list()
        if(response.status == 200){
          commit("SET_UNITS", response.data, {root: true})
        }
        return response
      } catch (e){
        return e.response
      }
    },
    getCancers: async ({commit})=> {
      try{
        let response = await cancer_list()
        if(response.status == 200 && response?.data?.status == 'success'){
          let temp = {}
          response?.data?.data.forEach(row=> {
            temp[row.key] = row
          })
          commit("SET_CANCERS", temp, {root: true})
          return response;
        }
      } catch (e){
        return e.response
      }
    },
    setShowLoading({commit}, show){
      commit('SET_SHOW_LOADING', show)
    }
  },
  getters: {
    units: state => state.units,
    cancers: state => state.cancers,
    show_loading: state => state.show_loading,
  }
})