<template lang="pug">
Modal(:id="modal_id" :title="title")
  template(v-slot:modal_body v-on:cancel="cancel")
    
    pdf(
      v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
    )
  template(v-slot:modal_footer)
    a.btn.btn-outline-secondary(:href="pdf_url" download) 下載
    button.btn.btn-outline-danger(type='button' data-dismiss='modal' @click="cancel") 關閉
</template>

<script>
import Modal from './Modal'
import pdf from 'vue-pdf'
export default {
  components: {
    Modal,
    pdf
  },
  props: {
    modal_id: {
      default: 'modal_id',
      type: String
    },
    pdf_url: {
      default: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      type: String
    },
    title: {
      default: 'PDF TiTle',
      type: String
    }
  },
  data(){
    return {
      numPages: undefined
    }
  },
  computed:{
    src(){
      return pdf.createLoadingTask(this.pdf_url);
    }
    
  },
  mounted(){
    this.src.promise.then(pdf => {
			this.numPages = pdf.numPages;
		});
  },
  methods: {
    cancel(){

      // console.log("cancel")

    },
    confirm(){
      // console.log("confirm")
    }
  }
};
</script>

<style scoped></style>
