<template>
  <div :class="{'home': isHome}" id="app">
    <loading></loading>
    <keep-alive>
      <component :is="(isHome) ? 'home-nav' : 'header-nav'"></component>
    </keep-alive>
    <keep-alive>
      <router-view class="router-view" :class="{'pt-md-5': isHome, 'pt-5': !isHome} " v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <vue-page-transition name="fade-in-right">
      <router-view class="router-view" :class="{'pt-md-5': isHome, 'pt-5': !isHome} " v-if="!$route.meta.keepAlive"></router-view>
    </vue-page-transition>
    <keep-alive>
      <component :is="(isHome) ? 'home-footer' : 'main-menu'"></component>
    </keep-alive>
  </div>
</template>

<script>
import loading from '@/components/Loading'
import headerNav from '@/components/HeaderNav'
import mainMenu from '@/components/mainMenu'
import homeFooter from '@/components/Home/footer'
import homeNav from '@/components/Home/nav'
export default {
  metaInfo: {
    title: 'PROPHET CARE',
    titleTemplate: '%s | PROPHET CARE',
    htmlAttrs: {
      lang: 'zh-TW',
    },
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: '北醫,臺北醫學大學,健檢中心,健保資料庫,健康存摺,國內醫療,不須侵入式檢查,疾病預測,大數據,大數據研發,精準預防,早覺醫療,早知道,早做到,PROPHET,PRediction Of Principle HEalth Threat' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'author', content: 'Localhostco.' },
      { property: 'og:url', content: window.location.href},
      { property: 'og:type', content: "website"},
      { 
        vmid: 'og_title', 
        property: 'og:title', 
        content: "創新AI重大疾病早覺管理",
        template: chunk => `${chunk} - PROPHET CARE`,
      },
      { vmid: 'og_description', property: 'og:description', content: "北醫,臺北醫學大學,健檢中心,健保資料庫,健康存摺,國內醫療,不須侵入式檢查,疾病預測,大數據,大數據研發,精準預防,早覺醫療,早知道,早做到,PROPHET,PRediction Of Principle HEalth Threat"},
      { vmid: 'og_image', property: 'og:image', content: "/img/logo.png"},
      { property: 'fb:app_id', content: "3625381844159606"},
    ]
  },
  components: {
    headerNav,
    mainMenu,
    homeNav,
    homeFooter,
    loading
  },
  computed: {
    isHome(){
      return this.$route.name == 'home'
    }
  },
  created(){

  }
}
</script>
<style lang="scss">
html {
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
}

#app {
  position: relative;
  top: 0;
  left: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 768px;
  margin: 0px auto;
}
.home{
  height: auto !important;
  min-height: auto !important;
  max-width: 100vw !important;
  .router-view{
    padding-bottom: 0 !important;
  }
}

.main-menu {
  // top: calc(100vh - 70px);
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.16);
  z-index: 9999;
  transition: 0.1s;
  height: 70px;

  &.active {
    /*height: 100vh;*/
    top: 40px;
    overflow: auto;
    padding: 20px 30px 40px;
  }

  &.hide-menu {
    top: 100vh;
  }
}

@keyframes rotateMobile {
  0% {
  }
  90% {
    transform: rotate(-90deg);
  }
}
.box-shadow{
  -webkit-box-shadow: 0 3px 4px 1px rgba(217,217,217,1);
  -moz-box-shadow: 0 3px 4px 1px rgba(217,217,217,1);
  box-shadow: 0 3px 4px 1px rgba(217,217,217,1);
}
.btn-box-shadow{
  -webkit-box-shadow: 0 3px 4px 1px rgba(217,217,217,1);
  -moz-box-shadow: 0 3px 4px 1px rgba(217,217,217,1);
  box-shadow: 0 3px 4px 1px rgba(217,217,217,1);

  &:hover{
    -webkit-box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
    -moz-box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
    box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
  }
  &:active{
    -webkit-box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
    -moz-box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
    box-shadow: 0 3px 4px 1px rgba(8,54,204,0.3);
  }
}
.el-message-box{
  width: auto !important;
}

.router-view{
  padding-bottom: 15vh;
}
</style>
