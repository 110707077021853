<template lang="pug">
cookie-law(theme="prophet" 
  :position="position" 
  :transitionName="transitionName" 
  :cookieOptions="cookieOptions" 
  v-on:accept="acceptCookie"
  v-on:decline="acceptCookie")
  .container.text-center.m-auto.py-md-3(slot-scope="props")
    button.btn.btn.btn-sm.btn-outline-secondary.rounded-circle#close(@click="props.close") 
      font-awesome-icon(size='sm' :icon="['fas', 'times']")
    p 本公司於網站中使用cookie🍪 
    p 
      | 本網站使用cookies以改善網站和用戶的使用體驗，繼續瀏覽本網站表示您接受我們的隱私權政策與cookies聲明。了解更多
      router-link(:to="{name: 'privacy_terms'}") 隱私權政策。
    div.d-flex.flex-row.align-content-center.justify-content-center
      button.btn.btn-primary.mx-5(@click="props.accept")
        font-awesome-icon(size='lg' :icon="['fas', 'check']")
      button.btn.btn-danger.mx-5(@click="props.decline") 
        font-awesome-icon(size='lg' :icon="['fas', 'ban']")

</template>
<script>
import GDPRMixin from '@/mixins/GDPRMixin'
import CookieLaw from 'vue-cookie-law'
export default {
  props: {
    position: {
      default: 'bottom',
      type: String
    },
    transitionName: {
      default: 'slideFromBottom',
      type: String
    },
  },
  mixins: [GDPRMixin],
  components: { CookieLaw },
  created(){
    if(this.isExpire){
      this.resetAcceptCookies()
    }
  },
}
</script>
<style lang="scss" scoped>

.Cookie--prophet {
  padding-top: 1rem;
  #close{
    top: 15px;
    right: 15px;
    position: absolute;
    padding: 0 .37rem;
  }
  background-color: #0F264D;
  color: #eee;
}
</style>