import { mapGetters, mapActions } from 'vuex'
export default{
  computed: {
    ...mapGetters("Mhb", ['mhb_histories', 'downloadProgress']),
  },
  methods: {
    ...mapActions("Mhb", [
      "doDownload",
      "storeInfo",
      "getMHBHistories",
      "toggleDownloadProgress"
    ]),
  }
}