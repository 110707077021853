<template lang="pug">
div.container
  .form-group.pt-3
    label.font-weight-bolder(for="gender") 性別
    div
      .custom-control.custom-radio.custom-control-inline(v-for="(row,index) in gender_option" :key="`gender_${index}`")
        input.custom-control-input(
          :checked="row.val == gender" 
          :value="row.val" 
          type='radio' 
          name="gender"
          :id="`gender_${index}`"
          disabled)
        label.custom-control-label(:for='`gender_${index}`') {{row.label}}
        
  form.pt-3

    .form-group
      label.font-weight-bolder(for='weight') 您的身高為？(cm)
      input#height.form-control(
        type='number' 
        v-model="form.height"
        @blur="$v.form.height.$touch"
        :class="{ 'is-invalid': $v.form.height.$error || getErrorStr('height') }"
        placeholder="身高"
      )
      .invalid-feedback(v-if="!$v.form.height.required") 必填欄位
      .invalid-feedback(v-if="!$v.form.height.numeric") 格式錯誤
    .form-group
      label.font-weight-bolder(for='weight') 您的體重為？(kg)
      input#weight.form-control(
        type='number' 
        v-model="form.weight"
        @blur="$v.form.weight.$touch"
        :class="{ 'is-invalid': $v.form.weight.$error || getErrorStr('weight') }"
        placeholder="體重"
      )
      .invalid-feedback(v-if="!$v.form.weight.required") 必填欄位
      .invalid-feedback(v-if="!$v.form.weight.numeric") 格式錯誤
      
    .w-100.border.mx-auto.my-4
    .form-group.mt-3.mb-0
      label.d-block.font-weight-bolder 1. 吸菸狀態
      .invalid-feedback(:class='{"d-block": $v.form.smoking.$error || getErrorStr("smoking")}' v-if="!$v.form.smoking.required") 必填欄位
      .form-check.d-inline-block.mr-3.mb-2(
        v-for="smoking in smoking_list" 
        :key="`smoking_${smoking.val}`")
        input.form-check-input(
          type='radio' 
          name="smoking"
          v-model="form.smoking" 
          :id="`smoking_${smoking.val}`" 
          :value="smoking.val"
          
          :class='{"is-invalid": $v.form.smoking.$error || getErrorStr("smoking") }')
        label.form-check-label(:for='`smoking_${smoking.val}`') {{ smoking.label }}
    .w-100.border.mx-auto.my-4
    .form-group.mt-3.mb-0
      label.d-block.font-weight-bolder 2. 飲酒狀態
      .invalid-feedback(:class='{"d-block": $v.form.alcoholism.$error || getErrorStr("alcoholism")}' v-if="!$v.form.alcoholism.required") 必填欄位
      .form-check.d-inline-block.mr-3.mb-2(
        v-for="alcoholism in alcoholism_list" 
        :key="`alcoholism${alcoholism.val}`")
        input.form-check-input(
          type='radio' 
          name="alcoholism"
          v-model="form.alcoholism" 
          :id="`alcoholism${alcoholism.val}`" 
          :value="alcoholism.val"
          :class='{"is-invalid": $v.form.alcoholism.$error || getErrorStr("alcoholism") }')
        label.form-check-label(:for='`alcoholism${alcoholism.val}`') {{ alcoholism.label }}
    .w-100.border.mx-auto.my-4
    .form-group.mt-3.mb-0
      label.d-block.font-weight-bolder 3. 請問您的家族史有？
      .invalid-feedback(:class='{"d-block": $v.form.disease.$error || getErrorStr("disease")}' v-if="!$v.form.disease.required || !$v.form.disease.minLength") 必填欄位
      .form-check.d-inline-block.mr-3.mb-2(
        v-for="row in diseases_list" 
        :key="`disease_${row.val}`"
        v-if="gender == 'unknown' || row.gender == gender || row.gender == 'all'")
        input.form-check-input(
          name="disease"
          type='checkbox' 
          v-model="form.disease" 
          :id="`disease_${row.val}`" 
          :value="row.val"
          @change="optionChanged('disease', row.val)"
          :class='{"is-invalid": $v.form.disease.$error || getErrorStr("disease") }')
        label.form-check-label(:for='`disease_${row.val}`') {{ row.label }}

    .w-100.border.mx-auto.my-4
    .form-group.mt-3.mb-0
      label.d-block.font-weight-bolder 4. 您現階段是否有下列之症狀？
      .invalid-feedback(:class='{"d-block": $v.form.symptom.$error || getErrorStr("symptom")}' v-if="!$v.form.symptom.required || !$v.form.symptom.minLength") 必填欄位
      .form-check.d-inline-block.mr-3.mb-2(
        v-for="row in symptom_list" 
        :key="`symptom_${row.val}`"
        v-if="gender == 'unknown' || row.gender == gender || row.gender == 'all'")
        input.form-check-input(
          name="symptom"
          type='checkbox' 
          v-model="form.symptom" 
          :id="`symptom_${row.val}`" 
          :value="row.val"
          @change="optionChanged('symptom', row.val)"
          :class='{"is-invalid": $v.form.symptom.$error || getErrorStr("symptom") }')
        label.form-check-label(:for='`symptom_${row.val}`') {{ row.label }}
    
    .w-100.border.mx-auto.my-4
    .form-group.mt-3.mb-0
      label.d-block.font-weight-bolder 5.請問最近一年您是否曾因下列原因就診？
      .invalid-feedback(:class='{"d-block": $v.form.reason_see_doctor.$error || getErrorStr("reason_see_doctor")}' v-if="!$v.form.reason_see_doctor.required || !$v.form.reason_see_doctor.minLength") 必填欄位
      .form-check.d-inline-block.mr-3.mb-2(
        v-for="row in reasons_see_doctor" 
        :key="`reason_${row.val}`"
        v-if="gender == 'unknown' || row.gender == gender || row.gender == 'all'")
        input.form-check-input(
          name="reason"
          type='checkbox' 
          v-model="form.reason_see_doctor" 
          :id="`reason_${row.val}`" 
          :value="row.val"
          @change="optionChanged('reason_see_doctor', row.val)"
          :class='{"is-invalid": $v.form.reason_see_doctor.$error || getErrorStr("reason_see_doctor") }')
        label.form-check-label(:for='`reason_${row.val}`') {{ row.label }}
    
    
    .d-flex.flex-row.align-content-center.justify-content-center.mt-5
      button.btn.btn-outline-primary.w-50.box-shadow.mx-3(@click.stop.prevent="saveMedHistory") 送出 & 預測
      button.btn.btn-outline-secondary.w-50.box-shadow.mx-3(type="reset" @click.stop.prevent="resetForm") 重新填寫
</template>
<script>
const DEFAULT_FORM = {
  height: 0,
  weight: 0,
  smoking: "",
  alcoholism: "",
  disease: [],
  symptom: [],
  reason_see_doctor: [],
};
import UserMixin from '@/mixins/UserMixin'
import UserMedHistoryMixin from '@/mixins/UserMedHistoryMixin'
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  mixins: [UserMixin, UserMedHistoryMixin, validationMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      gender_option: [
        {label: '男', val: 'male'},
        {label: '女', val: 'female'},
        {label: '不願透露', val: 'unknown'},
      ],
      diseases: [
        { val: 'none', label: '無', gender: 'all' },
        { val: "hypertension", label: "高血壓" },
        { val: "diabetes", label: "糖尿病" },
        { val: "heart disease", label: "心臟病" },
        { val: "cataract", label: "白內障" },
        { val: "stroke", label: "中風" },
        { val: "epilepsy", label: "癲癇" },
        { val: "asthma", label: "氣喘" },
        { val: "tuberculosis", label: "肺結核" },
        { val: "kidney disease", label: "腎臟病" },
        { val: "Liver disease", label: "肝病" },
        { val: "anemia", label: "貧血" },
        { val: "otitis media", label: "中耳炎" },
        { val: "hearing impaired", label: "聽力障礙" },
        { val: "thyroid disease", label: "甲狀腺疾病" },
        { val: "fracture", label: "骨折" },
        { val: "peptic ulcer disease", label: "消化性潰瘍、胃炎" },
        { val: "gastroesophageal reflux disease", label: "逆流性食道炎" },
        { val: "chronic bronchitis", label: "慢性氣管炎、肺氣腫" },
        
      ],
      diseases_list: [
        { val: 'none', label: '無', gender: 'all' },
        { val: 'gastroenteric', label: '胃腸道癌', gender: 'all'},
        { val: 'prostate', label: '攝護腺癌', gender: 'male'},
        { val: 'breast', label: '乳癌', gender: 'female'},
        { val: 'ovarian', label: '卵巢癌 ', gender: 'female'},
        { val: 'type_2_diabetes', label: '第二型糖尿病', gender: 'all'},
        { val: 'chronic_ancreatitis', label: '慢性胰腺炎', gender: 'all'},
        { val: 'copd', label: '慢性阻塞性氣道疾病（COPD）', gender: 'all'},
        { val: 'endometrial_polyps', label: '子宮內膜增生或息肉', gender: 'female'},
      ],
      smoking_list: [
        {val: 'never', label: '從未吸菸'},
        {val: 'quit', label: '已戒菸'},
        {val: 'mild', label: '輕度吸菸者(每月少於10支)'},
        {val: 'moderate', label: '中度吸菸者(每月10支至19支)'},
        {val: 'severe', label: '重度吸菸者(每月20支以上)'},
      ],
      alcoholism_list: [
        {val: 'never', label: '從未飲酒'},
        {val: 'less_than_one_per_day', label: '小於一罐/天'},
        {val: 'less_than_two_per_day', label: '一至二罐/天'},
        {val: 'more_than_three_per_day', label: '三罐以上/天'},
      ],
      symptom_list: [
        { val: 'none', label: '無', gender: 'all' },
        { val: 'loss_of_appetite', label: '食慾不振', gender: 'all' },
        { val: 'rapid_weight_loss', label: '體重急速下降', gender: 'all' },
        { val: 'stomach_ache', label: '腹痛', gender: 'all' },
        { val: 'swollen_abdomen', label: '腹部腫脹', gender: 'all' },
        { val: 'hard_to_swallow', label: '吞嚥困難', gender: 'all' },
        { val: 'heartburn', label: '胃灼熱', gender: 'all' },
        { val: 'indigestion', label: '消化不良', gender: 'all' },
        { val: 'rectal_bleeding', label: '直腸出血 ', gender: 'all' },
        { val: 'vomiting_blood', label: '吐血', gender: 'all' },
        { val: 'cough_and_blood', label: '咳嗽有血', gender: 'all' },
        { val: 'hematuria', label: '血尿', gender: 'all' },
        { val: 'testicular_mass', label: '睾丸腫塊', gender: 'male' },
        { val: 'testicular_pain', label: '睾丸疼痛', gender: 'male' },
        { val: 'bleeding_after_menopause', label: '停經後出血', gender: 'female' },
        { val: 'irregular_menstrual_bleeding', label: '月經不規則出血', gender: 'female' },
        { val: 'postcoital_bleeding', label: '性行為後陰道出血', gender: 'female' },
        { val: 'breast_lumps', label: '乳房腫塊', gender: 'female' },
        { val: 'nipple_discharge', label: '乳頭溢液', gender: 'female' },
        { val: 'breast_pain', label: '乳房疼痛', gender: 'female' },
        { val: 'lump_on_the_neck', label: '脖子上有腫塊', gender: 'all' },
        { val: 'night_sweats', label: '盜汗', gender: 'all' },
        { val: 'venous_thromboembolism', label: '靜脈血栓栓塞', gender: 'all' },
      ],
      reasons_see_doctor: [
        { val: 'none', label: '無', gender: 'all' },
        { val: 'reasons_see_doctor1', label: '改變排便習慣', gender: 'all' },
        { val: 'reasons_see_doctor2', label: '便秘', gender: 'all' },
        { val: 'reasons_see_doctor3', label: '咳嗽', gender: 'all' },
        { val: 'reasons_see_doctor4', label: '無法解釋的瘀傷', gender: 'all' },
        { val: 'reasons_see_doctor5', label: '貧血', gender: 'all' },
        { val: 'reasons_see_doctor6', label: '尿滯留', gender: 'male' },
        { val: 'reasons_see_doctor7', label: '頻尿', gender: 'male' },
        { val: 'reasons_see_doctor8', label: '夜尿症(夜間排尿)', gender: 'male' },
        { val: 'reasons_see_doctor9', label: '勃起功能障礙', gender: 'male' },
        
      ],
      form: {
        height: 0,
        weight: 0,
        smoking: "",
        alcoholism: "",
        disease: [],
        symptom: [],
        reason_see_doctor: [],
      }
    
    }
  },
  validations: {
    form: {
      height:  {
        required,
        numeric
      },
      weight: {
        required,
        numeric
      },
      smoking: {
        required
      },
      alcoholism: {
        required
      },
      disease: {
        required,
        minLength: minLength(1)
      },
      symptom: {
        required,
        minLength: minLength(1)
      },
      reason_see_doctor: {
        required,
        minLength: minLength(1)
      },
    }
  },
  mounted(){
    this.initForm()
  },
  methods: {
    saveMedHistory(){
      this.$v.form.$touch()

      if(!this.$v.form.$invalid){
        this.storeHistory({
          user_id: this.user?.id,
          form: this.form
        }).then(response=> {
          if(response.status == 200 && response?.data?.status == "success"){
            this.$router.push({name: 'predict'})
          }
        })
      }
    },
    resetForm(){
      this.$confirm(`是否是否重新填寫病史問卷？`, '是否重新填寫', {
          confirmButtonText: '重新填寫',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        }).then(() => {
          this.$v.form.$reset()
          this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        }).catch(() => {
          this.$notify({
            type: 'info',
            title: '已取消'
          });
        });
    },
    initForm(){
      if(this.med_history?.id){
        this.form = {...this.form, ...this.med_history?.data}
      }
    },
    optionChanged(field, val){
      let temp = [...[], ...this.form[field]]
      if(val == 'none'){
        temp = temp.indexOf('none') != -1 ? [val] : []
      } else if(temp.indexOf('none') != -1){
        temp.splice(temp.indexOf('none'), 1)
      }
      this.form[field] = temp
      this.$v.form?.[field]?.$touch()
    },
  },
  watch: {
    med_history: {
      handler: function(newVal, oldVal){
        this.initForm()
        return oldVal
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>

</style>