<template lang="pug">
.container
  .py-3
    h1.text-center {{cancer.title}}
    .w-50.m-auto
      picture(v-if="cancer.img_path")
        source(:srcset='cancer.img_path' :type='`image/${cancer.img_path_ext}`')
        img.w-100(:src='cancer.img_path' alt='Alt Text!')
  .py-3(v-html="cancer.description")
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.cancer?.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.cancer?.meta_description
        },
        {
          vmid: "og_title",
          property: "og:title",
          content: this.cancer?.title
        },
        {
          vmid: "og_description",
          property: "og:description",
          content: this.cancer?.meta_description
        },
        {
          vmid: "og_image",
          property: "og:image",
          content: this.cancer?.img_path
        }
      ]
    };
  },
  computed: {
    ...mapGetters({cancers: 'cancers'}),
  },
  created(){
    if(this.$route?.params?.type){
      this.type = this.$route?.params?.type
    }
    this.doGetCancer()
  },
  data(){
    return {
      cancer: {
        id: 0,
        key: '',
        title: null,
        img_path: null,
        descritption: '',
        meta_description: '',
        img_path_ext: 'png'
      },
      type: "breast"
    }
  },
  methods:{
    ...mapActions({
      getCancers: 'getCancers'
    }),
    doGetCancer: async function(){
      let response = await this.getCancers()
      if(response.status == 200){
        if(this.cancers?.[this.type]){
          this.cancer = this.cancers?.[this.type]
          this.cancer.img_path_ext = this.cancer.img_path.split('.').pop()
        }
        
        
      }
    }
  }
}
</script>