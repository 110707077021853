<template lang="pug">
div.container
  .row.unit-img.mb-3
    img.logo(alt="logo" src="/img/logo.png")
    .cover(v-if="unitImg")
      img.box-shadow(:src="unitImg")
  div.form.p-3.my-3
    .form-group
      label(for="unit") 單位
      select.form-control#unit(
        :class="{ 'is-invalid': $v.form_user.info.unit_id.$error }" 
        v-model="form_user.info.unit_id" 
        @blur="$v.form_user.info.unit_id.$touch" 
        required)
        option(value="0") 請選擇
        option(v-for="unit in units" :key="`unit_id_${unit.id}`" :value="unit.id") {{unit.title}}
      .invalid-feedback(v-if="!$v.form_user.info.unit_id.selected") 必填欄位
    .form-group
      label(for="email") Email
      input.form-control#email(
        type="text" 
        v-model.lazy="form_user.email" 
        :class="{ 'is-invalid': $v.form_user.email.$error || getErrorStr('email') }" 
        @blur="$v.form_user.email.$touch" 
        aria-describedby="emailHelp" 
        placeholder="test@example.com" 
        required)
      .invalid-feedback(v-if="!$v.form_user.email.required") 必填欄位
      .invalid-feedback(v-if="!$v.form_user.email.email") 格式錯誤
      .invalid-feedback {{ getErrorStr('email') }}
    .form-group
      label(for="password") 密碼
      input.form-control#password(
        type="password" 
        :class="{ 'is-invalid': $v.form_user.password.$error || getErrorStr('password') }"
        @blur="$v.form_user.password.$touch"
        v-model="form_user.password" aria-describedby="personalIdHelp")
      .invalid-feedback(v-if="!$v.form_user.password.required") 必填欄位
      .invalid-feedback(v-if="!$v.form_user.password.minLength") 最少需要8個字數
      .invalid-feedback {{ getErrorStr('password') }}
    //- .form-group
    //-   label(for="personal_id") 身份證
    //-   input.form-control#personal_id(
    //-     type="text" 
    //-     :class="{ 'is-invalid': $v.form_user.personal_id.$error || getErrorStr('personal_id_hash') }"
    //-     @blur="$v.form_user.personal_id.$touch"
    //-     v-model="form_user.personal_id" 
    //-     aria-describedby="personalIdHelp")
    //-   .invalid-feedback(v-if="!$v.form_user.personal_id.required") 必填欄位
    //-   .invalid-feedback(v-if="!$v.form_user.personal_id.alphaNum") 格式錯誤
    //-   .invalid-feedback(v-if="!$v.form_user.personal_id.minLength") 格式錯誤
    //-   .invalid-feedback {{ getErrorStr('personal_id_hash') }}

    p.statement.text-justify 按下「註冊」，即表示你同意 {{ $appName }} 的
      a(href="" data-toggle="modal" data-target="#term_of_use") 使用條款與條件。
    p.statement.text-justify 要深入瞭解 {{ $appName }} 如何收集、使用、分享及保護你的個人資料，請詳閱 {{ $appName }} 的
      a(href="" data-toggle="modal" data-target="#privacy_policy") 隱私權政策。
    .text-center
      button.btn.btn-outline-secondary.w-100(@click.stop.prevent="signUp") 註冊
    .text-center.my-4
      | 已有帳戶嗎？ 
      a.text-primary(@click="$router.push({name: 'signIn'})") 登入。
  PdfModal(id="term_of_use" title="使用條款與條件" pdf_url="/pdf/test.pdf")
  PdfModal(id="privacy_policy" title="隱私權政策" pdf_url="/pdf/test.pdf")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import UserMixin from '@/mixins/UserMixin'
import PdfModal from '@/components/Modal/PdfModal'

export default {
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data() {
    return {
      form_user: {
        email: null,
        password: null,
        personal_id: null,
        remember_me: false,
        info: {
          unit_id: 0,
        }
      },
      form_errors: {}

    }
  },
  mixins: [UserMixin, validationMixin],
  computed: {
    ...mapGetters({
      units: 'units'
    }),
    unitImg(){
      let result = this.units.filter( unit=> unit.id == this.form_user?.info?.unit_id)
      return result?.[0]?.img
    }
  },
  components: {
    PdfModal
  },
  validations: {
    form_user: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8)
      },
      info: {
        unit_id: {
          selected: (value)=> {
            return value && value != 0
          },
        }
      }
    }
  },
  mounted(){
    this.form_user = {...{}, ...this.user}
    this.getUnits()
  },
  methods:{
    ...mapActions({
      getUnits: 'getUnits'
    }),
    signUp: async function() {
      this.$v.form_user.$touch()
      if(!this.$v.form_user.$invalid){
        let response = await this.doSignUp(this.form_user)
        if(response.status == 200){
          //route push to next page
          this.$router.push({name: 'profile'})
          
        }
        else {
          //push logout
          if(response?.data?.errors) {
            this.form_errors = response?.data?.errors
          }
          else {
            alert(response?.data?.msg)
          }
          
        }
      }
      
    },
    getErrorStr(field){
      if(this.form_errors?.[field])
        return this.form_errors?.[field].join(',')
      else 
        return ""
    }
    
  }
}
</script>

<style lang="scss" scoped>
.unit-img{
  position: relative;
  
  img.logo{
    object-fit: cover;
    width: 100%;
    height: 30vh;
  }
  .cover{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(33,33,33,0.5);
    img{
      position: absolute;
      left: calc((100% - 200px)/2);
      bottom: -30px;
      border-radius: 50%;
      object-fit: cover;
      width: 200px;
      max-width: 100%;
      height: 200px;
      background-color: #eee;
    }
  }
}
.statement{
  font-size: 13px;
  text-align: center;
}

</style>