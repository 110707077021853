const _EXPIRE_DAYS = 7
export default{
  computed: {
    cookieOptions() {
      let date = new Date;
      date.setDate(date.getDate() + _EXPIRE_DAYS);
      return {
        expires: date.toGMTString()
      }

    },
    cookies_accept(){
      return window.localStorage.getItem('cookie:accepted')
    },
    isExpire(){
      if(window.localStorage.getItem('cookie:accepted:expire')){
        let now = new Date();
        let date = new Date(window.localStorage.getItem('cookie:accepted:expire'))
        
        return now.getTime() >= date.getTime()
      }
      return true;
      
    },
  },
  methods: {
    resetAcceptCookies(){
      window.localStorage.setItem('cookie:accepted:expire', null)
      window.localStorage.setItem('cookie:accepted', null)
    },
    setAcceptCookieExpire(days){
      let now = new Date();
      now.setDate(now.getDate() + days)
      window.localStorage.setItem('cookie:accepted:expire', now.toGMTString())
    },
    acceptCookie(){
      if(this.cookies_accept == 'true'){
        this.$gtm.enable(true)
      }
      this.setAcceptCookieExpire(_EXPIRE_DAYS)
    },
  },
}