const pushAppierEvent = (event_type, parameters)=>{
  if(process.env.NODE_ENV != 'production') return;
  if(window.localStorage.getItem('cookie:accepted') == 'true'){
    dataLayer.push({
      event:'set_event',
      event_type,
      parameters
    });
  }
  
};
const pushAppierProfile = (profiles)=> {
  if(process.env.NODE_ENV != 'production') return;
  if(window.localStorage.getItem('cookie:accepted') == 'true'){
    dataLayer.push({
      event: "set_profiles",
      profiles
    });
  }
}



export {
  pushAppierEvent,
  pushAppierProfile
}