<template lang="pug">
div
  | Validating
</template>
<script>
import UserMixin from '@/mixins/UserMixin'
export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  created () {
    if(this.$route?.query?.login_token){
      let login_token = this.$route?.query?.login_token
      this.doTokenSignIn({login_token})
    }
  }
}
</script>