<template lang="pug">
div.container-fluid
  .content.my-1
    img.w-100(v-for="(path, index) in consent_imgs" :key="index" :src="path")
  .w-100.border-top.py-3.action-btns
    .d-flex.flex-row.justify-content-center.align-self-center
      button.btn.btn-primary.mx-2.w-50(@click="$router.push({name: 'signature'})") 同意
      button.btn.btn-outline-secondary.mx-2.w-50(@click="$router.push({name: 'predict'})") 不同意

</template>
<script>
import UserMixin from '@/mixins/UserMixin'
export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  data(){
    return {
      consent_imgs: [
        '/img/inform-consent/page1.jpg',
        '/img/inform-consent/page2.jpg',
        '/img/inform-consent/page3.jpg',
        '/img/inform-consent/page4.jpg',
        '/img/inform-consent/page5.jpg',
        '/img/inform-consent/page6.jpg',
      ]
    }
  },
  mounted(){
  },
}
</script>
<style lang="scss" scoped>
div.action-btns{
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #efefef;
}
div.content{
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>