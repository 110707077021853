<template lang="pug">
div.container
  .row.unit-img.mb-3
    img.logo(alt="logo" :src="unitImg")
    .cover
      img.box-shadow(v-if="user.info && user.info.avatar" :src="user.info.avatar")
      img.box-shadow(v-else src="/img/user.png")
      
  .form.mt-5(v-if="user.id > 0")
    .form-group
      label(for="email") 信箱
      input.form-control#email(
        type="text"
        :value="user.email" 
        disabled)
    .form-group(v-if="user.info && user.info.unit_id")
      label(for="unit") 單位
      select.form-control#unit(
        v-model="user.info.unit_id" 
        disabled)
        option(value="0") 請選擇
        option(v-for="unit in units" :key="`unit_id_${unit.id}`" :value="unit.id") {{unit.title}}
    
    .form-group(v-else)
      label(for="unit") 單位
      select.form-control#unit(
        v-model="form.unit_id" 
        @blur="$v.form.unit_id.$touch"
        :class="{ 'is-invalid': $v.form.unit_id.$error || getErrorStr('unit_id') }"
        )
        option(value="0") 請選擇
        option(v-for="unit in units" :key="`unit_id_${unit.id}`" :value="unit.id") {{unit.title}}
      .invalid-feedback(v-if="!$v.form.unit_id.required") 必填欄位
      .invalid-feedback(v-if="!$v.form.unit_id.minValue") 必填欄位
      .invalid-feedback {{ getErrorStr('unit_id') }}
    form
      .form-group
        label(for="name") 姓名
        input.form-control#name(
          type="text"
          v-model="form.name"
          placeholder="姓名"
          @blur="$v.form.name.$touch"
          :class="{ 'is-invalid': $v.form.name.$error || getErrorStr('name') }"
          )
        .invalid-feedback(v-if="!$v.form.name.required") 必填欄位
        .invalid-feedback {{ getErrorStr('name') }}
      
      .form-group
        label(for="gender") 性別
        div
          .custom-control.custom-radio.custom-control-inline(v-for="(gender,index) in gender_option" :key="`gender_${index}`")
            input.custom-control-input(
              v-model="form.gender" 
              :value="gender.val" 
              type='radio' 
              :id="`gender_${index}`" 
              @blur="$v.form.gender.$touch"
              :class="{ 'is-invalid': $v.form.gender.$error || getErrorStr('gender') }")
            label.custom-control-label(:for='`gender_${index}`') {{gender.label}}
        .invalid-feedback(:class="{'d-block': $v.form.gender.$error || getErrorStr('gender') }" v-if="!$v.form.gender.required") 必填欄位
        .invalid-feedback(:class="{'d-block': $v.form.gender.$error || getErrorStr('gender') }") {{ getErrorStr('gender') }}
      .form-group
        label.d-block 生日
        el-date-picker(
          type='date'
          :class="{'w-100': true}"
          v-model="form.birthday"
          placeholder="生日"
          :editable="false"
          @blur="date_picker_blur"
        )
        span.text-danger(v-if="$v.form.birthday.$error") 日期錯誤
      .form-group
        label(for="phone") 聯絡電話
        input.form-control#phone(
          type="text"
          v-model="form.phone"
          placeholder="聯絡電話")
      .text-center
        button.btn.btn-primary.w-100.my-2(@click.stop.prevent="updateInfo") 儲存
        button.btn.btn-outline-secondary.w-100.my-2(type='reset' @click="resetFrom") 重新填寫

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxValue, minValue } from 'vuelidate/lib/validators'
import UserMixin from '@/mixins/UserMixin'
export default {
  mixins: [UserMixin, validationMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      units: 'units'
    }),
    unitImg(){
      if(this.user?.units?.[0]?.img){
        return this.user?.units?.[0]?.img
      } else {
        return '/img/logo.png'
      }
    },
    
  },
  data() {
    return {
      form: {
        name: '',
        unit_id: 0,
        birthday: '',
        gender: null,
        phone: null,

      },
      from_errors: {},
      gender_option: [
        {label: '男', val: 'male'},
        {label: '女', val: 'female'},
        {label: '不願透露', val: 'unknown'},
      ]
    }
  },
  created(){
    this.getUnits()
  },
  mounted() {
    this.initForm()
  },
  validations: {
    form: {
      name: {
        required,
      },
      unit_id: {
        required,
        minValue: minValue(1)
      },
      birthday: {
        maxValue: maxValue(new Date())
      },
      gender: {
        required,
      },
    }
  },
  methods: {
    ...mapActions({
      getUnits: 'getUnits'
    }),
    updateInfo: async function(){
      this.$v.form.$touch()
      if(!this.$v.form.$invalid){
        let birthday = ''
        if(this.isValidDate(this.form?.birthday)){
          birthday = `${this.form.birthday.getFullYear()}-${this.form.birthday.getMonth() + 1}-${this.form.birthday.getDate()}`
        }
        let data = {
          ...this.form,
          ...{
            id: this.user.id,
            birthday
          }
        }
        let response = await this.doUpdateInfo(data)
        if(response.status == 200){
          this.$notify({
            title: '成功',
            message: '更新成功',
            type: 'success'
          });
          this.checkMedHistory()

        } else if (typeof response?.data?.errors != 'undefined'){
          this.$notify({
            title: '錯誤',
            message: '請檢查表單資訊',
            type: 'error'
          });
          this.form_errors = response?.data?.errors

        } else if (typeof response?.data?.msg != 'undefined'){
          this.$notify({
            title: '錯誤',
            message: response?.data?.msg,
            type: 'error'
          });
        }
      }
    },
    checkMedHistory(){
      if(this.med_history && this.med_history?.id) return;
      else this.$router.push({name: 'medical-history'})
    },
    date_picker_blur(date_picker){
      this.$v.form.birthday.$touch()
      return date_picker
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    resetFrom(){
      this.$v.form.$reset()
    },
    initForm(){
      let temp = {...{}, ...this.user}
      
      this.form.unit_id = temp?.info?.unit_id
      this.form.name = temp.name
      this.form.gender = temp?.info.gender
      this.form.phone = temp?.info.phone
      if(temp?.info?.birthday){
        this.form.birthday = new Date(temp.info.birthday)
      }
    }
  },
  watch: {
    'user.id': function(newVal, oldVal){
      this.initForm()
      return oldVal
    }
  }
}
</script>
<style lang="scss" scoped>
.box-shadow{
  -webkit-box-shadow: 0 2px 4px 0px rgba(77,77,77,1);
  -moz-box-shadow: 0 2px 4px 0px rgba(77,77,77,1);
  box-shadow: 0 2px 4px 0px rgba(77,77,77,1);
}
.unit-img{
  position: relative;
  
  img.logo{
    object-fit: cover;
    width: 100%;
    height: 25vh;
  }
  .cover{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(33,33,33,0.5);
    img{
      position: absolute;
      left: calc((100vw - 120px)/2);
      bottom: -30px;
      border-radius: 50%;
      object-fit: cover;
      width: 120px;
      height: 120px;
    }
  }
}
</style>