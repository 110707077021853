<template lang="pug">
.container-fluid
  .d-flex.flex-row.align-self-center.justify-content-center
    .actions.d-flex.flex-column.justify-content-center.align-self-center.pr-3
      button.btn.btn-outline-secondary.my-3.px-0(@click="reset") 
        span 重置
      button.btn.btn-outline-primary.my-3.px-0(@click="save")
        span 儲存
    .signature
      VueSignaturePad(class="border" width="80vw" height="85vh" ref="signaturePad")
  
</template>

<script>
import $ from 'jquery'
import UserMixin from '@/mixins/UserMixin'

export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  computed:{
    signature_pad_width(){
      return $(this.$refs.signaturePad.$el).width()
    }
  },
  mounted(){
    this.initSignaturePad()
  },
  
  methods: {
    initSignaturePad(){
      this.$refs.signaturePad.clearCacheImages()
      this.background_words()
    },
    background_words(){
      let ctx = this.$refs.signaturePad.$refs.signaturePadCanvas.getContext("2d")
      ctx.save();
      ctx.font = "40px Arial";
      ctx.rotate(90 * Math.PI / 180)
      ctx.fillStyle = 'rgba(242, 58, 92, 0.2)'
      ctx.fillText(`僅供本計劃之受試者同意書使用`, 100, -this.signature_pad_width / 2);
      ctx.restore();
    },
    reset() {
      this.$refs.signaturePad.clearCacheImages();
      this.background_words()
    },
    save() {
      let { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        this.doUploadSignature(data).then(response=> {
          if (response.status == 200 && response?.data?.status === "success") {
            this.$router.push({name: 'inform-consent-finished'})
          }
        })
      }
    },
    reSign(){
      this.resetUserSignature()
      setTimeout(()=> {
        this.initSignaturePad()
      }, 500)
      
    }
  },
}
</script>
<style lang="scss">
.actions{
  height: 85vh;
  button{
    width: 42px;
    height: 20vh;
  }
  button span {
    font-size: 18px;
    letter-spacing: 2px;
    display: inline-block;
    transform: rotate(90deg);
  }
}
.user_signature{

  img{
    border: 1px solid #eee ;
    object-fit: contain;
    width: 100%;
  }
}
</style>