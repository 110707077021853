<template lang="pug">
.container-fluid
  .row.content
    img(src="/img/construction.jpg")
  
</template>
<script>

export default {
  components: {
    
  }
}
</script>
<style lang="scss" scoped>
.content{
  height: 88vh;
  background-color: #ffc50c;
}
img{
  width: 100%;
  object-fit: contain;
}
</style>