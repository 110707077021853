<template lang="pug">
div
  // Masthead
  header.masthead
    .container.py-md-5
      .row.align-content-center.justify-content-center.text-center
        .col-lg-10.align-self-end
          h1.text-uppercase.text-white.font-weight-bold 創新AI重大疾病早覺管理
          hr.divider.my-4
        .col-lg-8.align-self-baseline
          p.text-white-75.font-weight-light.mb-5 體驗AI數位癌症篩檢
          a.btn.btn-primary.btn-xl(href='#' v-scroll-to="'#about'") Find Out More
    .bg-img
      .cover
      picture
        source(srcset='/img/home/pexels-matthias-zomer-339620.webp' type='image/webp')
        source(srcset='/img/home/pexels-matthias-zomer-339620.png' type='image/png')
        img(src='/img/home/pexels-matthias-zomer-339620.webp' alt='Alt Text!')
  section#about.brand-description
    .container.py-5
      h2 品牌故事
      p
        | 早覺醫療＝早知道、早做到
      p PROPHET(PRediction Of Principle HEalth Threat)，意為「先知者」，
      p 為能驅動早覺醫療模式，本產品透過AI人工智慧技術幫您提早鎖定狙擊，進行疾病精準預防，把握治療時機。
      p PROPHET.CARE於2020年成立，產品經過十年以上國內醫療大數據研發，不須侵入式檢查、僅需您過去的病史資料即可進行疾病預測。
      p 本產品亦與國內多家醫療院所、健檢中心合作，讓您不僅能「早知道」也能「早做到」。
      router-link(class="btn btn-lg btn-outline-primary" :to="{'name': 'signIn'}") 點我預測
    .bg-img
      .cover
      picture
        source(srcset='/img/home/brand-description.webp' type='image/webp')
        source(srcset='/img/home/brand-description.png' type='image/png')
        img(src='/img/home/brand-description.webp' alt='Alt Text!')
  section#services.w-100.py-5.position-relative
    picture
      source(srcset='/img/home/production-description.webp' type='image/webp')
      source(srcset='/img/home/production-description.jpg' type='image/png')
      img.w-100(src='/img/home/production-description.webp' alt='background')
  section.row.py-5.bg-light
    .col-12.px-3.pt-5
      picture
        source(srcset='/img/home/tech-model.webp' type='image/webp')
        source(srcset='/img/home/tech-model.jpg' type='image/png')
        img.w-100.m-auto(src='/img/home/tech-model.webp' alt='Alt Text!')
  //- section.know-future
  //-   .container.py-md-5
  //-     picture
  //-       source(srcset='/img/home/know-future.webp' type='image/webp')
  //-       source(srcset='/img/home/know-future.png' type='image/png')
  //-       img.w-100(src='/img/home/know-future.webp' alt='Alt Text!')
  //-   .bg-img
  //-     .cover.cover-white
  //-     picture
  //-       source(srcset='/img/home/pexels-bongkarn-thanyakij-3758765.webp' type='image/webp')
  //-       source(srcset='/img/home/pexels-bongkarn-thanyakij-3758765.png' type='image/png')
  //-       img(src='/img/home/pexels-bongkarn-thanyakij-3758765.webp' alt='background')
  section.text-center
    .container.py-md-5
      h3.mb-md-5 媒體報導
      iframe.m-auto(
        width="80%" 
        height="420" 
        src="https://www.youtube.com/embed/_ZOoNM2zlXc" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen)
  //- section#members.text-center
  //-   .container
  //-     h3.mb-md-5 團隊成員
  //-     .row(v-for="(members, chunk_index) in member_chunk" :key="`member_chunk${chunk_index}`")
  //-       .col-md-4.py-md-5.member(v-for="(member,index) in members" :key="`member_${index}`")
  //-         picture
  //-           source(:srcset="member['avatar_webp']" type='image/webp')
  //-           source(:srcset="member['avatar']" type='image/png')
  //-           img.my-4.rounded-circle(:src="member['avatar']" :alt="`${member['name']} ${member['title']}`")
  //-         h4 {{member["name"]}} {{member["title"]}}
  //-         p {{member["position"]}}
  //-         p(v-for="(unit,unit_arr_index) in member['unit_arr']" :key="`unit_${unit_arr_index}`")
  //-           | {{unit}}
  //-         p {{member['skill']}}
  //-         .w-75.d-block.d-md-none.border.mx-auto.mt-5(v-if="chunk_index != member_chunk.length - 1 || index != members.length -1")
  //- section.w-100.py-5.bg-light.text-center
  //-   .container.py-md-5
  //-     picture
  //-       source(srcset='/img/home/member-co-workers.webp' type='image/webp')
  //-       source(srcset='/img/home/member-co-workers.jpg' type='image/png')
  //-       img.w-100(src='/img/home/member-co-workers.webp' alt='Alt Text!')
  section#cooperator.w-100.py-5.bg-light.text-center
    .container.py-md-5
      h3 合作團隊
      picture
        source(srcset='/img/home/cooperator.webp' type='image/webp')
        source(srcset='/img/home/cooperator.png' type='image/png')
        img.w-100(src='/img/home/cooperator.webp' alt='Alt Text!')
    .bg-img
      .cover
      picture
        source(srcset='/img/home/cooperator-bg.webp' type='image/webp')
        source(srcset='/img/home/cooperator-bg.png' type='image/png')
        img(src='/img/home/cooperator-bg.webp' alt='Alt Text!')

</template>

<script>
import { pushAppierEvent } from '@/GTM'
import UserMixin from '@/mixins/UserMixin'
// import '@/assets/home/script.js'
export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
  computed: {
    member_chunk(){
      let row_num = 3
      let chunk = []
      let member_num = this.members.length
      let start = 0;
      while(start < member_num){
        chunk.push(this.members.slice(start, start + row_num))
        start += row_num
      }
      return chunk
    }
  },
  data(){
    return {
      members: [
        {
            'avatar': '/img/home/members/pic2.png',
            'avatar_webp': '/img/home/members/pic2.webp',
            'position': 'Founder',
            'name': '李友專',
            'title': '特聘教授',
            'unit_arr': ['臺北醫學大學','醫學資訊研究所'],
            'skill': '醫療人工智慧'
        },
        {
            'avatar': '/img/home/members/pic4.png',
            'avatar_webp': '/img/home/members/pic4.webp',
            'position': 'CEO',
            'name': '林承翰',
            'title': '執行長',
            'unit_arr': ['緯謙科技業務副理', '台北榮總資訊護理師'],
            'skill': '產品營運行銷'
        },
        {
            'avatar': '/img/home/members/pic3.png',
            'avatar_webp': '/img/home/members/pic3.webp',
            'position': 'CMO',
            'name': '林哲瑋',
            'title': '助理教授',
            'unit_arr': ['臺北醫學大學','醫學資訊博士'],
            'skill': '資訊管理、醫療新創'
        },
        {
            'avatar': '/img/home/members/pic5.png',
            'avatar_webp': '/img/home/members/pic5.webp',
            'position': 'CRO',
            'name': '楊軒佳',
            'title': '助理教授',
            'unit_arr': ['臺北醫學大學','醫學資訊研究所'],
            'skill': '臨床藥物資訊'
        },
        {
            'avatar': '/img/home/members/pic6.jpg',
            'avatar_webp': '/img/home/members/pic6.webp',
            'position': 'CFO',
            'name': '黃芝瑋',
            'title': '助理研究員',
            'unit_arr': ['臺北醫學大學','醫學科技學院'],
            'skill': '醫學資訊、醫務管理'
        },
        {
            'avatar': '/img/home/members/pic8.png',
            'avatar_webp': '/img/home/members/pic8.webp',
            'position': 'PM',
            'name': '王文吟',
            'title': '營養師',
            'unit_arr': ['國立臺灣海洋大學', '食品科學研究所'],
            'skill': '營養諮詢、專案管理'
        },
        {
            'avatar': '/img/home/members/pic9.jpeg',
            'avatar_webp': '/img/home/members/pic9.webp',
            'position': 'CRA',
            'name': '莊凱文',
            'title': '臨床研究員',
            'unit_arr': ['臺北醫學大學','醫學院醫學系'],
            'skill': '醫學研究資訊、臨床資訊'
        },
        {
            'avatar': '/img/home/members/pic10.jpeg',
            'avatar_webp': '/img/home/members/pic10.webp',
            'position': 'Data Scientist',
            'name': '朱昱瑾',
            'title': 'AI工程師',
            'unit_arr': ['臺北醫學大學','醫學資訊研究所'],
            'skill': '資料分析、深度學習'
        },
        {
            'avatar': '/img/home/members/pic11.jpeg',
            'avatar_webp': '/img/home/members/pic11.webp',
            'position': 'PM',
            'name': 'Annisa Ristya',
            'title': '',
            'unit_arr': ['臺北醫學大學','醫學資訊研究所'],
            'skill': '專案管理、自然語言處理'
        },
        // {
        //     'avatar': '/img/home/members/pic7.png',
        //     'avatar_webp': '/img/home/members/pic7.webp',
        //     'position': 'CTO',
        //     'name': '阮逢英',
        //     'title': '博士後研究員',
        //     'unit_arr': ['臺北醫學大學','醫學資訊研究所'],
        //     'skill': '巨量資訊分析、深度學習'
        // },
      ],
    }
  },
  mounted(){
    this.getUserStatus().then(response=> {
      if(this.user?.id){
        pushAppierEvent('home_page', {
          env: process.env.NODE_ENV,
          user_id: this.user?.id,
        })
      } 
    })
    this.handleScroll()
    // Collapse the navbar when page is scrolled
    $('#app').on('scroll', this.handleScroll)

    $("a").on('click', function() {
      $('.navbar-collapse').collapse('hide');
    });
  },
  methods: {
    handleScroll (e) {
        if (-$("header.masthead").offset().top > 100) {
        $("#mainNav").addClass("navbar-scrolled");
      } else {
        $("#mainNav").removeClass("navbar-scrolled");
      }
    }
  },
  destroyed () {
    $('#app').off('scroll');
  },
}
</script>
<style lang="scss" scoped>
@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
body,
html {
  width: 100%;
  height: 100%;
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: #f4623a;
}

hr.light {
  border-color: #fff;
}

.btn {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.page-section {
  padding: 8rem 0;
}


body {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

header.masthead {
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead .container{
    padding-top: 10vh;
    padding-bottom: 10vh;
    position: relative;
    z-index: 1000;
}
.bg-img{
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
  .cover{
    background-color: rgba(10,10,10, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
  .cover-white{
    background-color: rgba(233,233,233, 0.8) !important;
  }
  img{
    margin: 0 auto;
    object-fit: fill;
  }
}
header.masthead h1 {
  font-size: 2.25rem;
}
.brand-description{
  height: 70vh;
}
.brand-description, .know-future, #cooperator{
    position: relative;
  .container{
      color: #eee;
      position: relative;
      padding-bottom: 4.5rem;
      z-index: 1000;
  }
}



@media (max-width: 991px) {
    header.masthead {
      position: relative;
      height: 70vh;
      padding-top: 4.5rem;
      padding-bottom: 0;
    }

    .know-future, #cooperator{
      height: 50vh;
    }
    .know-future {
      padding-top: 10vh;
    }
    
  .bg-img{
    img{
      margin: 0 auto;
      height: 80vh;
    }
  }
}
@media (min-width: 992px) {
  header.masthead {
    height: 80vh;
    padding-top: 4.5rem !important;
    padding-bottom: 0;
  }
    

  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
  .bg-img{
    img{
      width: 140%;
    }
  }

}
@media (min-width: 1200px) {
  header.masthead {
    height: 70vh;
    padding-top: 4.5rem;
    padding-bottom: 0;
    h1 {
      font-size: 3.5rem;
    }
    .container{
      margin-top: 10vh;
    }
  }
  .brand-description{
    .container{
      margin-top: 10vh;
      p {
        font-size: 20px;
      }
    }
  }
  #cooperator{
    height: 100vh;
    position: relative;

    .container{
      height: 100%;
    }
  }
  .bg-img{
    img{
      width: 100%;
    }
  }

}

#portfolio .container-fluid, #portfolio .container-sm, #portfolio .container-md, #portfolio .container-lg, #portfolio .container-xl {
  max-width: 1920px;
}
#portfolio .container-fluid .portfolio-box, #portfolio .container-sm .portfolio-box, #portfolio .container-md .portfolio-box, #portfolio .container-lg .portfolio-box, #portfolio .container-xl .portfolio-box {
  position: relative;
  display: block;
}
#portfolio .container-fluid .portfolio-box .portfolio-box-caption, #portfolio .container-sm .portfolio-box .portfolio-box-caption, #portfolio .container-md .portfolio-box .portfolio-box-caption, #portfolio .container-lg .portfolio-box .portfolio-box-caption, #portfolio .container-xl .portfolio-box .portfolio-box-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(244, 98, 58, 0.9);
  transition: opacity 0.25s ease;
  text-align: center;
}
#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-sm .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-md .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-lg .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-xl .portfolio-box .portfolio-box-caption .project-category {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}
#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-sm .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-md .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-lg .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-xl .portfolio-box .portfolio-box-caption .project-name {
  font-size: 1.2rem;
}
#portfolio .container-fluid .portfolio-box:hover .portfolio-box-caption, #portfolio .container-sm .portfolio-box:hover .portfolio-box-caption, #portfolio .container-md .portfolio-box:hover .portfolio-box-caption, #portfolio .container-lg .portfolio-box:hover .portfolio-box-caption, #portfolio .container-xl .portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75);
}

#members{
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  img{
    border-radius: 50%;
    width: 140px;
    height: 140px;
    object-fit: cover;
  }
}

</style>