<template lang="pug">
.header
  GDPR(position="top" transitionName="slideFromTop")
  .container
    .d-flex.flex-row.justify-content-center.align-content-center
      .back.align-self-center(@click="back")
        font-awesome-icon(size='lg' :icon="['fas', 'chevron-left']")
      h1.page-title {{ $appName }} {{ pageTitle }}
      .empty
        router-link(:to="{'name': 'home'}")
          font-awesome-icon(size='lg' :icon="['fas', 'home']")
</template>
<script>
import GDPR from '@/components/GDPR'
export default {
  components: {
    GDPR
  },
  computed: {
    pageTitle(){
      return (this.$route?.meta?.title) ? ' - ' + this.$route?.meta?.title : ''
    }
  },
  methods: {
    back(){
      this.$router.go(-1)
      // console.log("back", window.history.length)
      // console.log("location.host", window.location.host)
      // console.log("location.host", document.referrer)
      // if(window.history.length > 1 && 
      //   document.referrer.indexOf(window.location.host) !== -1) {
      //     this.$router.go(-1)
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-shadow{
  -webkit-box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
  -moz-box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
  box-shadow: 0 3px 4px 1px rgba(222,222,222,1);
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  max-width:100%;
  z-index: 99;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #F2EFE9;
  h1.page-title{
    padding: 0;
    margin: 0 auto;
    font-size: 1.3em;
  }
}
.back, .empty{
  width: 30px;
}
</style>