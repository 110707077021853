import { get_downloads, store_mhb_info, mhb_download } from '@/Api'
import { pushAppierEvent } from '@/GTM.js'

export default {
  namespaced: true,
  state: () => ({
    mhb_histories: [],
    inDownloadProgress: false
  }),
  mutations: {
    SET_HISTORIES(state, list){
      state.mhb_histories = [...[], ...list]
    },
    RESET_HISTORIES(state){
      state.mhb_histories = []
    },
    TOGGLE_DOWNLOAD_PROGRESS(state){
      state.inDownloadProgress = !state.inDownloadProgress
    },
  },
  actions: {
    getMHBHistories({commit}){
      return get_downloads().then(response=> {
        if(response.status == 200 && response?.data?.status == 'success'){
          commit('SET_HISTORIES', response?.data?.data)
        }
        return response 
      }).catch(e=> {
        commit('RESET_HISTORIES')
        return e.response
      })
    },
    storeInfo(context, form){
      return store_mhb_info(form).then(response=> {
        if (response.status == 200 && response?.data?.status === "success") {
          this._vm.$notify({
            title: '成功',
            message: response?.data?.msg,
            type: 'success'
          });
          
          return response
        }
        
      }).catch(e=> {
        this._vm.$notify({
          title: '錯誤',
          message: e.response?.data?.msg,
          type: 'error'
        });
        return e.response
      })
    },
    doDownload({commit}, form){
      pushAppierEvent('download_mhb', {
        env: process.env.NODE_ENV,
        user_id: form.user_id,
      })
      return mhb_download(form).then(response=> {
          if (response.status == 200 && response?.data?.status === "success") {
            this._vm.$notify({
              title: '成功',
              message: response?.data?.msg,
              type: 'success'
            });
            commit("TOGGLE_DOWNLOAD_PROGRESS")
            return response
          }
        }).catch(e=> {
          this._vm.$notify({
            title: '錯誤',
            message: e.response?.data?.msg,
            type: 'error'
          });
          return e.response
        })
    },
    toggleDownloadProgress({commit}){
      commit("TOGGLE_DOWNLOAD_PROGRESS")
    }
  },
  getters: {
    mhb_histories: state => state.mhb_histories,
    downloadProgress: state=> state.inDownloadProgress
  }
}