import Vue from "vue";
import VueRouter from "vue-router";
import home from '../views/Home'
import predict from '../views/Predict'
import signIn from '../views/SignIn'
import signUp from '../views/SignUp'
import logout from '../views/Logout'
import profile from '../views/Profile'
import informConsent from '../views/InformConsent'
import informConsentFinished from '../views/InformConsentFinished'
import signature from '@/views/Signature'
import settingPage from '@/views/Setting'
import construction from '@/views/Construction'
import myHealthBank from '@/views/MyHealthBank'
import histories from '@/views/Histories'
import medicalHistory from '@/views/MedicalHistory'
import socialiteCallback from '@/views/SocialiteCallback'
import privacyTerms from '@/views/PrivacyTerms'
import cancer from '@/views/Cancer'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '首頁',
      authorization_required: false,
      show_menu: true,
      highLight: 'home'
    }
  },
  {
    path: '/predict',
    name: 'predict',
    component: predict,
    meta: {
      title: '預測',
      authorization_required: true,
      show_menu: true,
      highLight: 'predict'
    }
  },
  {
    path: '/myhealthbank',
    name: 'myhealthbank',
    component: myHealthBank,
    meta: {
      title: '健康存摺',
      authorization_required: true,
      show_menu: true,
      highLight: 'myhealthbank'
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: settingPage,
    meta: {
      title: '設定',
      authorization_required: true,
      show_menu: true,
      highLight: 'setting'
    }
  },
  {
    path: '/devices',
    name: 'devices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: construction,
    meta: {
      title: '穿戴裝置',
      authorization_required: true,
      show_menu: true,
      highLight: 'devices'
    }
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: signIn,
    meta: {
      title: '登入',
      authorization_required: false,
      show_menu: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout,
    meta: {
      title: '登出',
      authorization_required: false,
      show_menu: false
    }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: signUp,
    meta: {
      title: '註冊',
      authorization_required: false,
      show_menu: false,
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {
      title: '個人資料',
      authorization_required: true,
      show_menu: true,
      highLight: 'setting'
    }
  },
  {
    path: '/inform-consent',
    name: 'inform-consent',
    component: informConsent,
    meta: {
      title: '受試者同意書',
      authorization_required: true,
      show_menu: false,
      highLight: 'setting'
    }
  },
  {
    path: '/inform-consent-finished',
    name: 'inform-consent-finished',
    component: informConsentFinished,
    meta: {
      title: '受試者同意書',
      authorization_required: true,
      show_menu: false,
      highLight: 'setting'
    }
  },
  {
    path: '/signature',
    name: 'signature',
    component: signature,
    meta: {
      title: '簽名',
      authorization_required: true,
      show_menu: false,
      highLight: 'setting'
    }
  },
  {
    path: '/histories',
    name: 'histories',
    component: histories,
    meta: {
      title: '歷史紀錄',
      authorization_required: true,
      show_menu: true,
      highLight: 'predict'
    }
  },
  {
    path: '/medical-history',
    name: 'medical-history',
    component: medicalHistory,
    meta: {
      title: '自身病史',
      authorization_required: true,
      show_menu: true,
      highLight: 'setting'
    }
  },
  {
    path: '/socialite-callback',
    name: 'socialite-callback',
    component: socialiteCallback,
    meta: {
      title: '第三方登入',
      authorization_required: false,
      show_menu: false,
    }
  },
  {
    path: '/privacy_terms',
    name: 'privacy_terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: privacyTerms,
    meta: {
      title: '隱私權政策',
      authorization_required: false,
      show_menu: true,
      highLight: 'home'
    }
  },
  {
    path: '/cancer/:type?',
    name: 'cancer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: cancer,
    meta: {
      authorization_required: false,
      show_menu: true,
      highLight: 'home'
    }
  },
  
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to?.meta?.authorization_required){
    let token = window.localStorage.getItem("authtoken");
    if(token){
      next()
    } else {
      next('/sign-in')
    }
  }
  else next()
})

export default router
