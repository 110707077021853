<template lang="pug">
.container-fluid
  .row.content
    .col-12.p-0
      .list-group.list-group-flush
        li.list-group-item
          span.text-primary {{ user.name }}
          | 您好
        li.list-group-item(@click="$router.push({name: 'profile'})")
          font-awesome-icon(size="lg" :icon="['far', 'user-circle']")
          span.mx-2(href="#") 個人資料
          

        li.list-group-item(v-if="signature && signature.id" @click="$router.push({name: 'inform-consent-finished'})")
          span 受試者同意書 
          button.btn.btn-sm.btn-outline-success 已簽署
        li.list-group-item(v-else @click="$router.push({name: 'inform-consent'})")
          span 受試者同意書 
          button.btn.btn-sm.btn-outline-danger 未簽署
        li.list-group-item(@click="$router.push({name: 'medical-history'})")
          span 病史狀況 
          button.btn.btn-sm.btn-outline-success(v-if="med_history && med_history.id") 完成
          button.btn.btn-sm.btn-outline-danger(v-else) 未填寫
        li.list-group-item
          router-link(:to="{name: 'logout'}") 登出

</template>
<script>
import UserMixin from '@/mixins/UserMixin'

export default {
  mixins: [UserMixin],
  metaInfo() {
    return {
      title: this.$route?.meta?.title,
      meta: [
        // {
        //   vmid: "description",
        //   name: "description",
        //   content:
        //     "hello world, this is an example of adding a description with vueMeta"
        // }
      ]
    };
  },
}
</script>
<style lang="scss" scoped>
.list-group {
  li{
    font-size: 20px;
  }
}
</style>